<div class="card__order" *ngIf="!this.selected">
    <div class="card__title active-title" style="flex-direction: column;">
      <div style="font-size: 14px;">Troco</div>
      <div style="font-size: 11px; color: white;"></div>
    </div>
    <div class="card__choice__body active-body">
      <div class="card__choice__option" style="height: auto;">
        <div class="change">
          <!-- <span>Troco para:</span> -->
          <div class="box-input">
            <input class="form-control" style="font-size: 13px;" (input)="changeInput($event)" (blur)="changeFor(1)" [(ngModel)]="this.changeForValue" currencyBRL>
            <button class="button" style="color: #652969;" (click)="changeFor(1)" [disabled]="this.minPriceNot">Ok</button>
          </div>
          <span style="color: red; font-size: 11px;" *ngIf="this.minPriceNot">Insira um valor acima do valor do pedido.</span>
        </div>
      </div>
      <div class="card__choice__option" style="height: auto;">
        <div class="card__choice__option__title">
            <div style="padding-left: 10px; font-size: 14px;">Não preciso de troco</div>
        </div>
        <checkbox-radius (click)="changeFor(2)"></checkbox-radius>
      </div>
    </div>
</div>


<div class="card__order" *ngIf="this.selected">
  <div class="card__title">
    <div  style="font-size: 14px;">Troco</div>
  </div>
  <div class="card__selected__body">
      <ng-container *ngIf="this.typeSelected == 1">
          <div class="card__selected__option">
              <div class="card__selected__option__title">
                <div style="padding-left: 10px; font-size: 14px;">{{ this.changeForValue }}</div>
              </div>
              <div class="card__selected__option__button">
                <button (click)="changeButton()">Alterar</button>
              </div>
            </div>
      </ng-container>

      <ng-container *ngIf="this.typeSelected == 2">
        <div class="card__selected__option">
            <div class="card__selected__option__title">
              <div style="padding-left: 10px; font-size: 14px;">Não preciso de troco</div>
            </div>
            <div class="card__selected__option__button">
              <button (click)="changeButton()">Alterar</button>
            </div>
          </div>
    </ng-container>
  </div>
</div>
