
<div class="main" (click)="toggleCollapse()">


  <div class="box-collapse" [ngClass]="{'show': this.collapse}">
    <div class="box-status" *ngFor="let event of this.order.events; let last = last">
      <!-- <div class="circle-color pulse" *ngIf="last"></div> -->
      <div class="circle-color"></div>
      <div class="timer">{{ event.createdAt | date: 'HH:mm'  }}</div>
      <div class="label-status" [ngSwitch]="this.event.orderStatus">
        <span *ngSwitchCase="1">Aguardando a loja aceitar seu pedido...</span>
        <span *ngSwitchCase="2">Seu pedido esta em preparo</span>
        <span *ngSwitchCase="3">Seu pedido esta pronto</span>
        <span *ngSwitchCase="4">Seu pedido saiu para entrega</span>
        <span *ngSwitchCase="5">Pedido finalizado</span>
        <span *ngSwitchCase="0">Seu pedido foi cancelado pela loja</span>
        <span *ngSwitchCase="9">Aguardando Pagamento...</span>
        <span *ngSwitchCase="10">Seu pedido foi cancelado pela loja</span>
        <span *ngSwitchCase="11">Aguardando Reembolso...</span>
        <span *ngSwitchCase="12">Reembolso concluído</span>
        <span *ngSwitchCase="13">Pagamento aprovado</span>
      </div>
    </div>
    <!-- <div class="box-status" >
      <div class="circle-color"></div>
      <div class="timer">{{ this.order.createdAt| date: 'HH:mm'  }}</div>
      <div class="label-status">
        <span>Aguardando a loja aceitar seu pedido!</span>
      </div>
    </div> -->
  </div>

  <div class="title-collapse" [ngClass]="{'close': !this.collapse}">
    <div class="box-status" *ngIf="!this.collapse" style="margin-bottom: 0px;">
      <div class="circle-color"></div>
      <div class="timer">{{ this.lastEvent.createdAt| date: 'HH:mm'  }}</div>

      <div class="label-status" [ngSwitch]="this.lastEvent.orderStatus">
        <span *ngSwitchCase="1">Aguardando a loja aceitar seu pedido...</span>
        <span *ngSwitchCase="2">Seu pedido esta em preparo</span>
        <span *ngSwitchCase="3">Seu pedido esta pronto</span>
        <span *ngSwitchCase="4">Seu pedido saiu para entrega</span>
        <span *ngSwitchCase="5">Pedido finalizado</span>
        <span *ngSwitchCase="0">Seu pedido foi cancelado pela loja</span>
        <span *ngSwitchCase="9">Aguardando Pagamento...</span>
        <span *ngSwitchCase="10">Seu pedido foi cancelado pela loja</span>
        <span *ngSwitchCase="11">Aguardando Reembolso...</span>
        <span *ngSwitchCase="12">Reembolso concluído</span>
        <span *ngSwitchCase="13">Pagamento aprovado</span>
      </div>
    </div>
    <div class="icon-collapse" *ngIf="this.order.events.length > 1">
      <i class="fa-solid fa-angle-right arrows" [ngStyle]="{'transform':collapse ? 'rotate(270deg)' : 'rotate(90deg)' }"></i>
    </div>
  </div>

</div>

