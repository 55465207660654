<div class="top-bar" #topBar [ngClass]="{'fixed-small': this.fixedbar}">
  <ul #listBar>
    <ng-container *ngFor="let category of tabela; let i = index">
      <ng-container *ngIf=" this.filter == '' ">
        <li class="links" (click)="scrollToSection(i)" #navlink>
          <button><span>{{ category.categoryName }}</span></button>
          <div class="line-list-top-bar" #bar></div>
        </li>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let category of this.tabelaFiltrada; let i = index">
      <ng-container *ngIf=" this.filter != '' ">
        <li class="links" (click)="scrollToSection(i)" #navlink>
          <button><span>{{ category.categoryName }}</span></button>
          <div class="line-list-top-bar" [ngClass]="{'active-bar': barra}" #bar></div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
