import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventsService } from '../../services/events/events.service';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { CacheService } from '../../services/Cache/cache.service';

@Component({
  selector: 'app-receipt-card',
  templateUrl: './receipt-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css']
})
export class ReceiptCardComponent implements OnInit, AfterViewInit{
  step = false;
  company!: Company;
  selectedOption: { checked: boolean, value: number } = { checked: false, value: 0};
  @Output() optionSelected: EventEmitter<{ checked: boolean, value: number }> = new EventEmitter<{ checked: boolean, value: number }>();

  constructor(private eventsService: EventsService, private connection: ConnectionService, private cacheService: CacheService){}

  ngOnInit(): void {
      if(this.selectedOption.checked == false)
      {
        this.step = true;
      }
      this.eventsService.eventTypeReceipt$.subscribe((newValue) =>{
        if(newValue != 0){
          const data = {checked: true, value: newValue}
          this.onCheckboxChange(data)
        }
      })
      // if(this.eventsService.eventTypeReceipt != 0){
      //   const data = {checked: true, value: this.eventsService.eventTypeReceipt}
      //   this.onCheckboxChange(data)
      // }
      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        if(this.company.configs.receipt == 1)
        {
          const data = {checked: true, value: 5}
          this.onCheckboxChange(data)
        }
        else if(this.company.configs.receipt == 2)
        {
          const data = {checked: true, value: 3}
          this.onCheckboxChange(data)
        }
      })
  }
  ngAfterViewInit(): void {
    // if(this.company.configs.receipt == 1)
    // {
    //   const data = {checked: true, value: 5}
    //   this.onCheckboxChange(data)
    // }
    // else if(this.company.configs.receipt == 2)
    // {
    //   const data = {checked: true, value: 3}
    //   this.onCheckboxChange(data)
    // }
  }

  ngOnDestroy(): void {
    // this.eventsService.eventTypeReceipt = 0
    // this.selectedOption = {checked: false, value: 0}
    // this.optionSelected.emit({ checked: false, value: 0 });
  }

  onCheckboxChange(data: { checked: boolean, value: any }) {
    this.selectedOption = { checked: data.checked, value: data.value};
    this.optionSelected.emit({ checked: data.checked, value: data.value});
  }

  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0
    this.optionSelected.emit({ checked: false, value: 0 });
    this.eventsService.eventTypeReceipt = 0
  }
}
