import { trigger, style, animate, transition, state, keyframes } from '@angular/animations';

export const buttonVibration = trigger('buttonVibration', [
  state('inactive', style({ transform: 'translate(0, 0)' })),
  state('active-right', style({ transform: 'translateX(5px)' })),
  state('active-left', style({ transform: 'translateX(-10px)' })),
  transition('inactive <=> active-right', [
    animate('300ms', keyframes([
      style({ transform: 'translateX(0)' }),
      style({ transform: 'translateX(5px)' }),
    ])),
  ]),
  transition('active-right <=> active-left', [
    animate('300ms', keyframes([
      style({ transform: 'translateX(5px)' }),
      style({ transform: 'translateX(-10px)' }),
    ])),
  ]),
  transition('active-left <=> inactive', [
    animate('300ms', keyframes([
      style({ transform: 'translateX(-10px)' }),
      style({ transform: 'translateX(0)' }),
    ])),
  ]),
]);
