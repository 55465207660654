<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="my-content">
  <div style="max-width: 800px; margin: 0 auto;">


    <div class="navegation-header" *ngIf="!this.requestSucess">
      <div class="navegation-header__icon" (click)="goback()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div class="navegation-header__tittle">
        <h3>Meus pedidos</h3>
      </div>
    </div>
    <div class="box" *ngIf="!this.requestSucess">
      <div class="box-orders">
          <div class="box-orders__order">
            <div class="title-order">
              <div class="title-order__spans" style="gap: 6px;">
                <div style="width: 135px; height: 27px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
                <div style="width: 110px; height: 20px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
              </div>
              <div style="width: 81px; height: 30px; background-color: #cbd5dc; border-radius: 3px;">
                <div class="pre-loading"></div>
              </div>
            </div>
            <div class="price-order" style="background-color: #cbd5dc; width: 65px; height: 20px; border-radius: 3px;">
              <div class="pre-loading"></div>
            </div>
            <div class="button-detail-order" style="background-color: #cbd5dc; padding-top: 0px;  border-radius: 3px;">
              <div class="pre-loading" style="width: 100%; height: 52px;"></div>
            </div>
          </div>
          <div class="box-orders__order">
            <div class="title-order">
              <div class="title-order__spans" style="gap: 6px;">
                <div style="width: 135px; height: 27px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
                <div style="width: 110px; height: 20px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
              </div>
              <div style="width: 81px; height: 30px; background-color: #cbd5dc; border-radius: 3px;">
                <div class="pre-loading"></div>
              </div>
            </div>
            <div class="price-order" style="background-color: #cbd5dc; width: 65px; height: 20px; border-radius: 3px;">
              <div class="pre-loading"></div>
            </div>
            <div class="button-detail-order" style="background-color: #cbd5dc; padding-top: 0px;  border-radius: 3px;">
              <div class="pre-loading" style="width: 100%; height: 52px;"></div>
            </div>
          </div>
          <div class="box-orders__order">
            <div class="title-order">
              <div class="title-order__spans" style="gap: 6px;">
                <div style="width: 135px; height: 27px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
                <div style="width: 110px; height: 20px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
              </div>
              <div style="width: 81px; height: 30px; background-color: #cbd5dc; border-radius: 3px;">
                <div class="pre-loading"></div>
              </div>
            </div>
            <div class="price-order" style="background-color: #cbd5dc; width: 65px; height: 20px; border-radius: 3px;">
              <div class="pre-loading"></div>
            </div>
            <div class="button-detail-order" style="background-color: #cbd5dc; padding-top: 0px;  border-radius: 3px;">
              <div class="pre-loading" style="width: 100%; height: 52px;"></div>
            </div>
          </div>
          <div class="box-orders__order">
            <div class="title-order">
              <div class="title-order__spans" style="gap: 6px;">
                <div style="width: 135px; height: 27px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
                <div style="width: 110px; height: 20px; background-color: #cbd5dc; border-radius: 3px;">
                  <div class="pre-loading"></div>
                </div>
              </div>
              <div style="width: 81px; height: 30px; background-color: #cbd5dc; border-radius: 3px;">
                <div class="pre-loading"></div>
              </div>
            </div>
            <div class="price-order" style="background-color: #cbd5dc; width: 65px; height: 20px; border-radius: 3px;">
              <div class="pre-loading"></div>
            </div>
            <div class="button-detail-order" style="background-color: #cbd5dc; padding-top: 0px;  border-radius: 3px;">
              <div class="pre-loading" style="width: 100%; height: 52px;"></div>
            </div>
          </div>
      </div>

    </div>

    <div class="navegation-header" *ngIf="this.requestSucess">
      <div class="navegation-header__icon" (click)="goback()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div class="navegation-header__tittle">
        <h3>Meus pedidos</h3>
      </div>
    </div>
    <div class="box" *ngIf="this.requestSucess">
      <div class="box-orders" *ngIf="this.orders">
        <ng-container *ngFor="let order of orders; let last = last; let index = index">
            <div class="box-orders__order" [ngStyle]="{'padding-bottom.px': index == orders.length + 1 && this.theLastOrder ? 90 : 12}">
              <div class="title-order">
                <div class="title-order__spans">
                  <span class="span-order">Pedido #{{ order.displayId}}</span>
                  <span class="span-date">Em {{order.createdAt | datetimeFormat }}</span>
                </div>
                <div [ngSwitch]="order.orderStatus">
                  <div class="button-status" *ngSwitchCase="1" style="background-color: var(--primary-color); border-radius: 5px;">
                    <span class="status-order">Novo</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="2" style="background-color: #c96b0a; border-radius: 5px;">
                    <span class="status-order">Em preparo</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="3" style="background-color: green; border-radius: 5px;">
                    <span class="status-order">Pronto</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="4" style="background-color: green; border-radius: 5px;">
                    <span class="status-order">A caminho</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="5" style="background-color: #a9a9a9; border-radius: 5px; border: 1px solid #a1a1a1;">
                    <span class="status-order">Finalizado</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="0" style="background-color: #d70505; border-radius: 5px;">
                    <span class="status-order">Cancelado</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="9" style="background-color: #fda603; border-radius: 5px;">
                    <span class="status-order">Aguardando pagamento</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="10" style="background-color: #d70505; border-radius: 5px;">
                    <span class="status-order">Cancelado/Negado</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="11" style="background-color: #c96b0a; border-radius: 5px;">
                    <span class="status-order">Aguardando Reembolso</span>
                  </div>
                  <div class="button-status" *ngSwitchCase="12" style="background-color: green; border-radius: 5px;">
                    <span class="status-order">Reembolso concluido</span>
                  </div>

                </div>
              </div>
              <app-time-await [typeOrder]="order.orderType" [visualization]="1" [createdAtOrder]="order.createdAt" *ngIf="order.orderStatus == 1 || order.orderStatus == 2 || order.orderStatus == 3 || order.orderStatus == 4"></app-time-await>
              <app-input-copy-pix *ngIf="order.orderStatus == 9" [valueKey]="order.paymentForm[0].pixCode" [dateTimeTransation]="order.createdAt"></app-input-copy-pix>
              <app-progress-bar [order]="order"  *ngIf="this.order.orderStatus != 5 && this.order.orderStatus != 0 && this.order.orderStatus != 10 && this.order.orderStatus != 11 && this.order.orderStatus != 12"></app-progress-bar>

              <!-- <div>
                <ng-container [ngSwitch]="order.orderStatus">
                  <ul class="name-status">
                    <li *ngSwitchCase="1">Novo</li>
                    <li *ngSwitchCase="2">Em preparo</li>
                    <li *ngSwitchCase="3">Pronto</li>
                    <li *ngSwitchCase="4">Finalizado</li>
                  </ul>
                </ng-container>
                <div class="box-progress-bar">
                  <div class="progress-bar" [ngStyle]="{'width': (order.orderStatus == '1' ? 25 : (order.orderStatus == '2' ? 50 : (order.orderStatus == '3' ? 75 : (order.orderStatus == '4' ? 100 : 0)))) + '%'}">
                    <div class="animation-progress-bar"></div>
                  </div>
                </div>
              </div> -->

              <div class="price-order">
                <span>{{ order.orderPrice | currency:'BRL' }}</span>
              </div>
              <div class="button-detail-order">
                <button [routerLink]="['/', this.nameCompany, 'order-details', order.id_order]">Detalhes do pedido</button>
              </div>
            </div>
        </ng-container>
      </div>
      <ng-container *ngIf="this.totalOrders">
        <div class="button_plus_orders" *ngIf="this.totalOrders > 3 && this.totalOrders > this.quantityOrders">
          <button (click)="this.subquantityOrders()">Carregar mais</button>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>
