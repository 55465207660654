<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="my-content">
  <div>
    <div class="navegation-header">
      <div class="navegation-header__icon" (click)="goback()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div class="navegation-header__tittle">
        <h3>Confirmar Pedido</h3>
      </div>
    </div>

    <div class="box-user">
      <div class="user-detail" *ngIf="this.user">
        <span class="user-detail__two">{{this.name}}</span>
        <span class="user-detail__tree">{{this.phoneNumber}}</span>
      </div>
      <div class="user-detail__div-button">
        <button (click)="alterationEvent()"><i class="fa-solid fa-pen"></i>Editar</button>
      </div>
    </div>

    <!--Pedido-->
    <!-- <div class="box">
      <div class="box-tittle">
        <div>Seu pedido</div>
      </div>
      <div class="order" *ngFor="let item of this.bag; let i = index">
        <div class="order-content">
          <div class="order-content__info">
            <div class="picture-prod">
              <img [src]="item.imagem_prod" />
            </div>
            <div class="order-content__info__details">
              <span style="font-size: 14px; font-weight: 100;">{{ item.quantity }}x {{item.name}}</span>
              <div class="order-item__additional" >
                <div class="order-item__category" *ngFor="let categoryAdd of item.categories">
                  <span>{{ categoryAdd.categoryName }}</span>
                  <div class="order-item__additional__item" *ngFor="let adicional of categoryAdd.additional">
                    <ng-container *ngIf="categoryAdd.flavorCategory == 1">

                      <ng-container *ngIf="adicional.quantitySub == this.flavorQuantity[i]">
                        <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ 1 }}x</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                      </ng-container>
                      <ng-container *ngIf="adicional.quantitySub != this.flavorQuantity[i]">
                        <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ adicional.quantitySub }}/{{ this.flavorQuantity[i] }}</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                      </ng-container>

                    </ng-container>
                    <ng-container *ngIf="categoryAdd.flavorCategory == 0">
                      <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ adicional.quantitySub }}x </span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div *ngIf="item.observations != ''" style="font-size: 13px; color: #939393;">
                <span>Obs: </span><span>{{ item.observations }}</span>
              </div>
              <div class="info__detail__price">
                {{item.totalPrice | currency:'BRL' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="box">
      <div class="box-tittle">
        <div style="font-size: 14px;">Seu pedido</div>
      </div>
      <div class="order">
        <ng-container  *ngFor="let item of this.bag; let i = index">
          <div class="order-content">
            <div class="order-content__info">
              <div class="picture-prod">
                <img [src]="item.imagem_prod" />
              </div>
              <div class="order-content__info__details">
                <span style="font-size: 14px; font-weight: 100;">{{ item.quantity }}x {{item.name}}</span>
                <div class="order-item__additional" >
                  <div class="order-item__category" *ngFor="let categoryAdd of item.categories">
                    <span>{{ categoryAdd.categoryName }}</span>
                    <div class="order-item__additional__item" *ngFor="let adicional of categoryAdd.additional">
                      <ng-container *ngIf="categoryAdd.flavorCategory == 1">
  
                        <ng-container *ngIf="adicional.quantitySub == this.flavorQuantity[i]">
                          <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ 1 }}x</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                        </ng-container>
                        <ng-container *ngIf="adicional.quantitySub != this.flavorQuantity[i]">
                          <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ adicional.quantitySub }}/{{ this.flavorQuantity[i] }}</span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                        </ng-container>
  
                      </ng-container>
                      <ng-container *ngIf="categoryAdd.flavorCategory == 0">
                        <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d;">{{ adicional.quantitySub }}x </span><span style="font-weight: 100;">{{ adicional.nameSub }}</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="item.observations != ''" style="font-size: 13px; color: #939393; max-width: 200px;">
                  <span>Obs: </span><span style="word-wrap: break-word;">{{ item.observations }}</span>
                </div>
                <div class="info__detail__price">
                  {{item.totalPrice | currency:'BRL' }}
                </div>
              </div>
            </div>
  
          </div>
        </ng-container>

        <span *ngIf="this.observationActive" style="color: gray; font-size: 14px; word-wrap: break-word; padding: 5px 0 5px 12px;">Obs: {{ this.sacola.orderObservation }}</span>
        <!-- <hr> -->
        <div class="order-box-button" (click)="this.showModalObservation()" *ngIf="this.company.configs.orderObservationActive == 1">
          <span *ngIf="!this.observationActive">Adicionar observação</span>
          <span *ngIf="this.observationActive">Editar observação</span>
        </div>
      </div>

    </div>

    <app-observation-card (optionSelected)="observationOrder($event)" *ngIf="!this.hiddenCard"></app-observation-card>

    <method-card [methodReceipt]="this.methodReceiptSelected" (optionSelected)="typePayment($event)" *ngIf="this.observationSession.checked"></method-card>

    <app-payment-form-card [typeMethodPayment]="this.typeMethodSelected" [methodReceipt]="this.methodReceiptSelected" (optionSelected)="formPayment($event)" *ngIf="this.typeMethodSelected.checked"></app-payment-form-card>

    <app-form-change *ngIf="this.paymentFormSelected.value == 1" (change)="changeFor($event)"></app-form-change>

    <hr/>

    <app-cupom *ngIf="this.typeMethodSelected.checked" (coupon)="this.coupomSelected($event)" [typeOrder]="this.sacola.orderType" [productsOrder]="this.sacola.products" [orderPrice]="this.sacola.orderPrice" [id_customer]="this.user.id_customer"></app-cupom>

    <div class="box-cpf" id="conteudo"  style="margin: auto; max-width: 450px; padding: 0.5rem;" *ngIf="this.company.configs.documentOption == 1">
      <span>Documento</span>
      <input class="form-control" [ngClass]="{'is-valid': this.corretFormat == true, 'is-invalid': this.corretFormat == false}" [(ngModel)]="document" (input)="formatarDocumento($event)" name="document" placeholder="Digite seu CPF/CNPJ"   style="width: 100%;" maxlength="18">
    </div>

    <div class="price-total-order">
      <div class="total">
        <div class="summary-total">
          <div class="total-value">
            <div class="total-span">Subtotal</div>
            <div class="total-span">{{ this.subTotal | currency:'BRL' }}</div>
          </div>
        </div>
        <div class="total-value" *ngIf="this.sacola.discountValue && this.sacola.couponType != 3">
          <div style="font-size: 13px">Desconto</div>
          <div class="total-span" style="color: #09a509; font-size: 13px;">-{{ this.sacola.discountValue | currency:'BRL' }}</div>
        </div>
        <div class="div-total-value">
          <div class="content-total-value">
            <div>Total</div>
            <div>{{ this.sacola.orderPrice | currency:'BRL' }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="box-button">
    <button (click)="finalizationOrder()" [disabled]="this.disableButton" [ngClass]="{'disabled-positive': !this.abilitButton}">Finalizar pedido</button>
  </div>
</div>

<!--Card loading processamento de pedido-->
<app-loading-processing-order *ngIf="this.openLoadingComponent" [startComponent]="this.openLoadingComponent"></app-loading-processing-order>

<!--Modal User-->
<!-- <div class="modal" [ngClass]="{'show': openModal}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Confirme seus dados</h1>
      </div>
      <div class="modal-body">
        <div class="idendity">
          <div class="idendity-box-name">
            <div class="icon-content">
              <i class="fa-solid fa-user"></i>
            </div>
            <div class="content">
              <p>Nome</p>
              <h4>{{ this.name }}</h4>
            </div>
          </div>
          <div class="idendity-box-phone">
            <div class="icon-content">
              <i class="fa-solid fa-phone"></i>
            </div>
            <div class="content">
              <p>Telefone</p>
              <h4>{{ this.phoneNumber }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-edit" data-bs-dismiss="modal" [routerLink]="['/loja/user-data']">Editar</button>
        <button type="button" class="btn btn-confirm" (click)="openModal = false">Confirmar</button>
      </div>
    </div>
  </div>
</div> -->


<!--Modal Pix-->
<div class="modal" [ngClass]="{'show': openModalPix}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="font-size: 16px;" id="staticBackdropLabel">Pagamento Pix</h1>
      </div>
      <div class="modal-body" style="gap: 10px;">
        <div class="counter" *ngIf="!this.counterFinalizado">
          <i class="fa-solid fa-stopwatch" style="font-size: 20px; color: var(--primary-color);"></i>
          <app-counter-timing *ngIf="this.createdAtOrderPlaced != ''" [dateTimeTransaction]="this.createdAtOrderPlaced" (finalizionCounter)="this.finalizationCounter($event)"></app-counter-timing>
        </div>
        <span class="text-information">Copie o código Pix ou leia o Qrcode, e faça o pagamento no seu banco, antes do tempo expirar.</span>
        <div class="img-qrCode">
            <img [src]="this.base64QrPix" alt="QR Code" style="width: 100%; height: 100%;"/>
        </div>
        <div class="input-pix">
          <input [value]="this.pixCopyPaste" disabled/>
        </div>
        <app-copy-button style="width: 100%;" [valueInput]="this.pixCopyPaste"></app-copy-button>

        <div class="button-router">
          <button (click)="backOrders()">Acompanhar pedido</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Card loading Pix-->
<app-card-loading *ngIf="this.openCardLoading" [openCard]="true" [labelPerson]="'Aguarde enquanto geramos seu pix...'"></app-card-loading>

<div class="modal" [ngClass]="{'show': openModalObservation}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="font-size: 16px;" id="staticBackdropLabel">Observação</h1>
      </div>
      <div class="modal-body">
        <div class="modal-body-observation">
          <textarea not-emoji class="form-control text-area-observation" [value]="this.orderObservation" (input)="this.valueTextArea($event)"  placeholder="Adicione uma observação ao seu pedido:"></textarea>
          <div style="display: flex; font-size: 14px; color: #737373; padding-top: 2px;">
            <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/200</span>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-edit" (click)="this.cancelObservation()">Cancelar</button>
        <button type="button" class="btn btn-confirm" (click)="this.confirmObservation()">Confirmar</button>
      </div>
    </div>
  </div>
</div>
