import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderUser, Pedido } from 'src/app/Interfaces/pedido';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { OrderService } from 'src/app/shared/services/Order/order.service';
import { Cliente, UserService } from 'src/app/shared/services/User/user.service';
import { Location } from '@angular/common';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { paymentForm } from 'src/app/Interfaces/order';
import { Subscription } from 'rxjs';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Coupon } from 'src/app/Interfaces/coupon';

@Component({
  selector: 'app-order-confirmation-counter',
  templateUrl: './order-confirmation-counter.component.html',
  styleUrl: './order-confirmation-counter.component.css'
})
export class OrderConfirmationCounterComponent implements OnInit, AfterViewInit{
  private webHookSubscription!: Subscription;
  //#region Variaveis de estilo
    openModal = false;
    showTopMenu = true;
    eventNavegation = false;
    openLoadingComponent = false;
    abilitButton = false;
    openModalPix = false;
    counterFinalizado = false;
    openCardLoading = false;
    openModalObservation = false;
    observationActive = false;
    hiddenCard: boolean = false;
    corretFormat!: boolean | undefined;
    toastCloseStoreActive = false;
    disableButton = false;
  //#endregion

  //#region Variaveis Locais
    id_orderPix!: string;
    nameCompany: string = '';
    typeParam!: number;
    // counterParam: number = 0;
    createdAtOrderPlaced: string = '';
    company!: Company;
    bag: Pedido[] = [];
    name!: string;
    phoneNumber!: string;
    document: string = '';
    user!: Cliente;
    subTotal: number = 0;
    pixCopyPaste: string = '';
    base64QrPix: string = '';
    intervalId: any;
    paymentObject: paymentForm[] = []
    flavorQuantity: number[] = []
    orderObservation: string = '';
    countText = 0;
    observationSession = {
      checked: false,
      value: 0
    }
    
    methodReceiptSelected = {
      checked: true,
      value: 2
    };
  
    typeMethodSelected = {
      checked: false,
      value: 0
    }
  
    paymentFormSelected = {
      checked: false,
      value: 0
    }

    sacola: OrderUser = {
      id_companyFK: 0,
      id_customerFK: '',
      changeFor: '0',
      document_customer: '',
      orderType: 2,
      orderPrice: 0,
      orderObservation: '',
      orderStatus: '',
      paymentForm: this.paymentObject,
      onlinePay: 0,
      products: this.bag,
      id_coupon: '0',
      discountValue: 0,
      deliveryFee: 0,
      coupons: false,
      couponType: 0
    };

  //#endregion

  //#region Inicializadores

  constructor(
    private cacheService: CacheService,
    private router: Router,
    private toastr: ToastrService,
    private orderService: OrderService,
    private cartService: CartService,
    private authLayout: AuthLayoutComponent,
    private connection: ConnectionService,
    private eventsService: EventsService,
    private userService: UserService,
    private apiService: ApiService,
    private location: Location,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private webHookService: WebhookService,
    ){
      this.connection.nameCompany$.subscribe((newNameCompany) => {
        this.nameCompany = newNameCompany;
      })
      // this.connection.counterParam$.subscribe((newCounterParam) => {
      //   this.counterParam = newCounterParam
      // })
      this.eventsService.eventNavegation$.subscribe((newEvent) =>{
        this.eventNavegation = newEvent
      })
      this.authLayout.checkScreenSize();

      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        if(this.company.configs.configObservation == 0)
        {
          this.hiddenCard = true;
          this.observationOrder({ checked: true, value: 0, name: '' })
        }
      })
  }

  //Iniciado assim que o component é criado
  ngOnInit(): void {
    this.location.replaceState(`${this.nameCompany}/B`);
    this.disablePullToRefresh()

    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })

    this.connection.user$.subscribe(
    (User) =>{

      this.user = User;
    },
    (error) =>{
      if(this.cacheService.getItemLocal('user_key'))
      {
        this.user = this.cacheService.getItemLocal('user_key')
      }
    })

    this.apiService.getUsuario(this.user.phone).subscribe((data) =>{
      this.user = data.response.customer
      this.cacheService.setItemLocal('user_key', this.user)
      this.connection.updateUser(this.user)
    })

    this.bag = this.orderService.bag

    let count = 0
    this.bag.forEach((product, index) =>{
      count = 0
      product.categories.forEach(categorie =>{
        let value = 0
        categorie.additional.forEach(additional =>{
          value += additional.quantitySub
          if(categorie.flavorCategory == 1)
          {
            count += additional.quantitySub
          }
        })
      })
      this.flavorQuantity.push(count)
    })


    this.montarPedido();

    this.webHookSubscription = this.webHookService.getUpdates().subscribe(
      (content: any) => {
        if(content.type == 5){
          const data = content.data
          console.log(data);
          if(this.id_orderPix){
            if(data.id_order == this.id_orderPix)
              if(data.status == 1){
                clearInterval(this.intervalId)
                this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'order-details', this.id_orderPix])
              }
              else if(data.status == 6){
                clearInterval(this.intervalId)
                this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'pedidos'])
              }
          }

        }
      },
      (error) => {
        console.error('Erro ao receber mensagem do servidor WebHook:', error);
      }
    );
  }

  //Inicializado após o termino do carregamento DOM
  ngAfterViewInit(){
    setTimeout(() => {
      this.name = this.user.name
      this.phoneNumber = this.user.phone
    });
    // if(this.user)
    // {
    //   if(!this.eventNavegation)
    //   {
    //     this.showModal()
    //   }
    // }
    if(this.company){
      document.body.style.setProperty('--primary-color', this.company.configs.colorCompany);
      document.body.style.setProperty('--color-button-positive', this.company.configs.colorCompany);
      document.body.style.setProperty('--color-button-negative', this.company.configs.colorCompany);
    }
  }

  ngOnDestroy(): void {
    if(this.webHookSubscription) {
      this.webHookSubscription.unsubscribe();
    }
    clearInterval(this.intervalId);
  }

  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
    }

    //Função responsavel para não mostrar o modal caso tenha ido editar o usuario
    alterationEvent(){
      this.eventsService.eventNavegationSubject.next(true)
      this.userService.editingUserSubject.next(true);
      this.router.navigate([this.nameCompany, 'user-data'])
    }

    //O primeiro modal a ser aberto, quando o component inicia
    showModal(){
      setTimeout(() => {
        this.openModal = true
      }, 100);

    }

    //Função que é chamada para fazer a verificação se todos campos estão requeridos para abilitar o botão de finalizar o pedido
    AbilitButton(){
      if(this.typeMethodSelected.checked)
      {
        if(this.paymentFormSelected.checked){
          if(this.corretFormat == true || this.corretFormat == undefined){
            this.abilitButton = true;
          }
          else{
            this.abilitButton = false;
          }
        }
        else{
          this.abilitButton = false;
        }
      }
      else{
        this.abilitButton = false;
      }
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    observationOrder(data: { checked: boolean, value: number, name: string }){
      this.observationSession = data
      if(data.checked == false && data.value == 0){
        
        this.typeMethodSelected = data
        this.paymentFormSelected = data

        this.sacola.localization = ''
        this.sacola.paymentForm = []
        this.paymentObject = [];
      }

      this.sacola.localization = data.name;
      this.AbilitButton();
      
    }

    typePayment(data: { checked: boolean, value: number })
    {
      this.typeMethodSelected = data
      if(data.checked == false && data.value == 0)
      {
        this.paymentFormSelected = data
        // limpa a Forma de pagamento
        this.sacola.paymentForm = []
        this.paymentObject = [];
      }

      //Limpa o tipo de pagamento
      this.sacola.onlinePay = data.value
      this.AbilitButton();

    }

    formPayment(data: { checked: boolean, value: number })
    {
      this.paymentFormSelected = data
      if(data.checked == false && data.value == 0)
      {
        this.sacola.paymentForm = []
        this.paymentObject = []
      }
      else{

        const form: paymentForm = {
          changeFor: "0",
          onlinePay: this.typeMethodSelected.value,
          payStatus: 0,
          paymentForm: this.paymentFormSelected.value,
          paymentValue: this.sacola.orderPrice.toString()
        }

        //add ele no array e add o array no pedido
        this.paymentObject.push(form)
        this.sacola.paymentForm = this.paymentObject
      }
      this.AbilitButton();
    }

    coupomSelected(event: any){

      if(event.check){
        let coupon: Coupon = event.coupon

        if(coupon.percent != 0)
        {
          const discontFormated = coupon.percent / 100;
          let discont = this.sacola.orderPrice * discontFormated;
          this.sacola.orderPrice = this.sacola.orderPrice - discont;
          this.sacola.discountValue = discont;
          this.sacola.id_coupon = coupon.id_coupon;
          this.sacola.coupons = true;
          this.sacola.couponType = 1;
        }
        else if(coupon.value != 0){
          let discont = coupon.value
          this.sacola.orderPrice = this.sacola.orderPrice - discont
          this.sacola.discountValue = discont;
          this.sacola.id_coupon = coupon.id_coupon
          this.sacola.coupons = true,
          this.sacola.couponType = 2
        }
      }
      else{
        this.deslectedCupom()
      }
    }

    //Desseleciona o cupom e retira suas propriedades no pedido
    deslectedCupom(){
      if(this.sacola.discountValue)
      {
        this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.discountValue;
        this.sacola.id_coupon = '0';
        this.sacola.coupons = false;
        this.sacola.discountValue = 0;
        this.sacola.couponType = 0;
      }
    }

    goback(): void{
      if(this.company.company.status == 1){
        this.router.navigate([this.nameCompany, 'carrinho']);
        this.eventsService.eventNavegationSubject.next(false)
      }
      else{
        this.router.navigate([this.nameCompany, 'B']);
        this.eventsService.eventNavegationSubject.next(false)
        this.eventsService.eventTypeReceiptSubject.next(0)
      }

    }

    changeFor(event: any){
      if(typeof event == 'number')
      {
        this.sacola.changeFor = event.toString()
        this.sacola.paymentForm[0].changeFor = event.toString()
      }
    }

    showModalObservation(){
      this.openModalObservation = true;
    }

    confirmObservation(){
      this.openModalObservation = false
      this.sacola.orderObservation = this.orderObservation
      if(this.sacola.orderObservation?.length != 0){
        this.observationActive = true;
      }
      else{
        this.observationActive = false;
      }
    }

    cancelObservation(){
      this.openModalObservation = false;
      this.orderObservation = this.sacola.orderObservation

      if(!this.observationActive){
        this.sacola.orderObservation = '';
      }
    }

    valueTextArea(event: any){
      const inputElement = event?.target as HTMLInputElement;

      if(inputElement.value.length <= 200){
        this.orderObservation = inputElement.value
        this.countText = this.orderObservation.length;
      }
      else[
        inputElement.value = this.orderObservation
      ]

    }


    //#endregion

  //#region Montagem e finalização do pedido

    //Monta o objeto de pedido de usuario, tanto para Pedido em balcão quanto em delivery
    montarPedido(){
      this.sacola = {
        id_customerFK: this.user.id_customer,
        document_customer: this.document,
        changeFor: '0',
        id_companyFK: this.company.company.id_company,
        products: this.bag,
        orderType: 2,
        orderStatus: '1',
        paymentForm: this.paymentObject,
        orderObservation: '',
        onlinePay: 0,
        orderPrice: this.calcTotalOrder(),
        id_coupon: '0',
        discountValue: 0,
        deliveryFee: 0,
        coupons: false,
        couponType: 0
      }
    }

    //Manda o pedido para o serviço e limpa os itens do carrinho
    finalizationOrder(){
      this.disableButton = true;
      this.apiService.verifiStatusCompany(this.company.company.id_company).subscribe(
        (data) =>{
          if(data[0].open){
            this.eventsService.eventNavegationSubject.next(false)
            this.eventsService.eventTypeReceiptSubject.next(0)

            if(this.sacola.onlinePay == 1){
              this.paymentPix(this.sacola);
            }
            else{
              this.orderService.OrderUser(this.sacola);
              this.router.navigate([this.nameCompany, 'processing']);
            }
      
            this.cartService.clearCart();
          }
          else{
            if(!this.toastCloseStoreActive)
              {
                this.toastCloseStoreActive = true;
                this.toastr.error('Desculpe, mas a loja já fechou!', 'Loja Fechada',
                { positionClass: 'toast-top-right', timeOut: 0, tapToDismiss: true,}
                ).onHidden.subscribe(() =>{ this.toastCloseStoreActive = false});
              }
  
              this.connection.Empresa(this.nameCompany, this.typeParam).subscribe(
                (data) =>{
                  console.log('Status da loja atualizado');
                },
                (error) =>{
                  console.log('Erro inesperado', error)
                }
              )
          }
        },
        (error) =>{

        })




    }

    //Quando o usuario seleciona o pix, função responsavel de montalo
    paymentPix(sacola: OrderUser){
      this.openCardLoading = true;
      this.orderService.OrderUserPaymentPix(sacola).subscribe(
        (data) =>{
          this.apiService.paymentPix(data).subscribe((dataPayment) =>{
            this.createdAtOrderPlaced = data.createdAt;
            console.log(dataPayment);
            this.openModalPix = true;
            this.pixCopyPaste = dataPayment.pagamento
            this.base64QrPix = 'data:image/png;base64,' + dataPayment.pagamentoQrCode
            this.openCardLoading = false;
            this.id_orderPix = data.id_order
            this.startVerification(data.id_order, this.company.company.id_company);

          })
        },(error) =>{
          this.openCardLoading = false;

        });
    }

    startVerification(id_order: string, id_company: number){
      this.apiService.startVerification(id_order, id_company).subscribe(
        () =>{
          console.log('O Servidor iniciou a verificação')
          this.intervalId = setInterval(() => {
            this.getStatusOrder(id_order)
          }, 10000) 
        },
        (error) =>{
          console.log(error);
        }
      )
    }

    getStatusOrder(id_order: string){
      this.apiService.getStatusOrder(id_order).subscribe(
        (data) =>{
          if(data.status == 1){
            clearInterval(this.intervalId)
            this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
            this.router.navigate([this.nameCompany, 'order-details', this.id_orderPix])
          }
          else if(data.status == 10){
            clearInterval(this.intervalId)
            this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
            this.router.navigate([this.nameCompany, 'pedidos'])
          }
        },
        (error) =>{

        }
      )
    }

    //Volta para view de pedidos
    backOrders(){
      this.router.navigate([this.nameCompany, 'pedidos']);
    }

    //Fecha o contador quando o tempo termina
    finalizationCounter(event: any){
      this.counterFinalizado = event
    }

    //Calcula o total da ordem para ser montado o objeto e feito o request
    calcTotalOrder(){
      let total = 0
      this.bag.forEach(product => {
        total += product.totalPrice
      });

      this.subTotal = total

      return total
    }
  //#endregion

  //#region Formatação de documentos CPF - CNPJ

    formatarDocumento(event: any) {
      const newValue = event.target.value;

      const rawValue = newValue.replace(/\D/g, '');
      let formatted = this.formatarCPF(rawValue);

      if(rawValue.length == 0){
        this.abilitButton = true
        this.corretFormat = undefined
      }
      if(rawValue.length >= 1  && rawValue.length < 11)
      {
        this.abilitButton = false;
        this.corretFormat = false
      }
      else if(rawValue.length == 11){
        this.corretFormat = true
        this.AbilitButton()
      }
      else if(rawValue.length >= 12 && rawValue.length < 14){
        this.abilitButton = false;
        this.corretFormat = false;
      }
      else if(rawValue.length == 14){
        this.corretFormat = true
        this.AbilitButton()
      }


      if (rawValue.length <= 11) {
        formatted = this.formatarCPF(rawValue);
      } else {
        formatted = this.formatarCNPJ(rawValue);
      }
      this.document = formatted
      this.sacola.document_customer = this.document
    }

    formatarCPF(cpf: string): string {

      const areaCode = cpf.substring(0, 3);
      const firstPart = cpf.substring(3, 6);
      const secondPart = cpf.substring(6, 9);
      const treePart = cpf.substring(9, 11);
      if(cpf.length <= 2)
      {
        return `${areaCode}`;
      }
      else if(cpf.length >= 2 && cpf.length <= 5 )
      {
        return `${areaCode}.${firstPart}`
      }
      else if(cpf.length >= 5 && cpf.length <= 8 )
      {
        return `${areaCode}.${firstPart}.${secondPart}`
      }
      else
      {
        return `${areaCode}.${firstPart}.${secondPart}-${treePart}`
      }
    }

    formatarCNPJ(cnpj: string): string {

      const area1 = cnpj.substring(0, 2)
      const area2 = cnpj.substring(2, 5)
      const area3 = cnpj.substring(5, 8)
      const area4 = cnpj.substring(8, 12)
      const area5 = cnpj.substring(12, 14)



      if(cnpj.length <= 13)
      {
        return `${area1}.${area2}.${area3}/${area4}-`
      }
      else{
        return `${area1}.${area2}.${area3}/${area4}-${area5}`
      }
    }

  //#endregion

  //#endregion

}
