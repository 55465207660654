import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface event{
  eventType: number;
  id_company: string;
  id_order: string;
  tableNumber: number
  eventObservation: string;
}

@Injectable({
  providedIn: 'root'
})

export class EventsService {

  //Usado para quando clica em editar o usuario na view confirmação de pedido
  eventNavegationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  eventNavegation$: Observable<boolean> = this.eventNavegationSubject.asObservable();

  //evento usado para quando o filtro de pesquisa estiver != de vazio e quando o usuario clica em detalhes do produto
  eventFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  eventFilter$: Observable<boolean> = this.eventFilterSubject.asObservable();

  //Usando para quando o tipo de recebimento do pedido estiver selecionado na view confirmação de pedido
  eventTypeReceiptSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  eventTypeReceipt$: Observable<number> = this.eventTypeReceiptSubject.asObservable();

  eventTypeReceipt: number = 0



  constructor() { }
}
