<input type="text" [value]="valueInput" #textInput style="display: none;">
<div class="component-cotent">
    <div class="button" (click)="copyText(textInput.value)">
        <div class="icon-button">
            <i class="fa-solid fa-copy"></i>
        </div>
        <span class="label-button">Copiar</span>
    </div>
</div>

