import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { CacheService } from '../../services/Cache/cache.service';
import { CartService } from '../../services/Cart/cart.service';
import { typeInterface } from 'src/app/Interfaces/company';

@Component({
  selector: 'app-top-menu-two',
  templateUrl: './top-menu-two.component.html',
  styleUrls: ['./top-menu-two.component.css']
})
export class TopMenuTwoComponent {
  @ViewChild('buttonSearch') buttonSeach!: ElementRef;
  @ViewChild('divInput', {static: false}) divInput!: ElementRef;
  @ViewChild('input') input!: ElementRef;
  @ViewChild('imgCompany') imgCompany!: ElementRef;
  @ViewChild('divIcon') divIcon!: ElementRef;
  @ViewChild('inputDesktop') inputDesktop!: ElementRef;
  @ViewChild('divBar') divBar!: ElementRef;
  @ViewChild('topMenu') topMenu!: ElementRef;

    //#region Variaveis locais

    private typeParam!: typeInterface;
    nameCompany: string = '';
    scrollTopPosition: number = 0;
    img!: string;
    company!: Company
    nameEmpresa: string = "";
    verificationTable!: boolean;
    counterCart: number = 0;
    userConfirmation = this.cacheService.getItemLocal('userConfirmation');
  //#endregion

  //#region Inicializadores

    constructor(
                private changeDetectorRef: ChangeDetectorRef,
                private router: Router,
                private connection: ConnectionService,
                private authLayout: AuthLayoutComponent,
                private cacheService: CacheService,
                private cartService: CartService
                ){
                  this.cartService.counter$.subscribe(newCount => {
                    this.counterCart = newCount;
                  });

    }

    //Chama as funções assim que o component é inicializado
    ngOnInit(): void {
      this.connection.company$.subscribe(company => {
        this.company = company
        this.nameCompany = company.company.companyName
      })

      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam;
      })

      if(this.typeParam.type == 1 || this.typeParam.type == 2){
        this.verificationTable = true
      }
      else{
        this.verificationTable = false
      }

    }

    //Chama as funções assim que todo o DOM da aplicação foi carregado
    ngAfterViewInit(){

      this.connection.company$.subscribe((newImage) =>{
        this.img = newImage.company.logo;
      })

      // console.log(this.img)
      this.changeDetectorRef.detectChanges();
    }
}
