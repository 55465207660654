<div class="card__order" *ngIf="!this.selectedOption.checked">
<div class="card__title" [ngClass]="{'active-title': this.step}">
    <div style="font-size: 14px;">Selecione o tipo do pagamento</div>
</div>
<div class="card__choice__body" [ngClass]="{'active-body': this.step}">
  <div class="card__choice__option">
    <div class="card__choice__option__title">
      <div class="card__choice__option__title__icon">
        <i class="fa-solid fa-mobile-screen-button" style="font-size: 20px;"></i>
      </div>
        <div style="padding-left: 10px; font-size: 14px;">Pagamento no app</div>
    </div>
    <div class="box-flag">
      <div class="flag-recomended">
        <span>Recomendado</span>
      </div>
      <checkbox-radius style="display: flex; align-items: center;" [checkboxData]="{ checked: selectedOption.checked, value: 1 }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
    </div>

  </div>

    <div class="card__choice__option" *ngIf="this.methodReceipt.value == 5">
        <div class="card__choice__option__title">
          <div class="card__choice__option__title__icon">
            <i class="fa-solid fa-motorcycle" style="font-size: 20px;"></i>
          </div>
            <div style="padding-left: 10px; font-size: 14px;">Pagamento na entrega</div>
        </div>
        <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: 0 }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
    </div>
    
    <div class="card__choice__option" *ngIf="this.methodReceipt.value == 3 || this.methodReceipt.value == 2">
        <div class="card__choice__option__title">
            <div class="card__choice__option__title__icon">
              <i class="fa-solid fa-store" style="font-size: 16px;"></i>
            </div>
            <div style="padding-left: 10px; font-size: 14px;">Pagamento no balcão</div>
        </div>
        <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: 0 }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
    </div>
</div>
</div>

<div class="card__order" *ngIf="this.selectedOption.checked">
    <div class="card__title">
      <div  style="font-size: 14px;">Tipo do pagamento</div>
    </div>
    <div class="card__selected__body">
        <ng-container *ngIf="this.selectedOption.value == 1">
            <div class="card__selected__option" *ngIf="this.company.configs.onlineAccept == 1"  style="height: 47px;">
                <div class="card__selected__option__title">
                  <div class="card__choice__option__title__icon">
                    <i class="fa-solid fa-mobile-screen-button" style="font-size: 20px;"></i>
                  </div>
                  <div style="padding-left: 10px; font-size: 14px;">Pagamento no app</div>
                </div>
                <div class="card__selected__option__button">
                  <button (click)="changeButton()">Alterar</button>
                </div>
              </div>
        </ng-container>

        <ng-container *ngIf="this.selectedOption.value == 0 && this.methodReceipt.value == 5">
            <div class="card__selected__option" style="height: 47px;">
                <div class="card__selected__option__title">
                  <div class="card__choice__option__title__icon">
                    <i class="fa-solid fa-motorcycle" style="font-size: 20px;"></i>
                  </div>
                  <div style="padding-left: 10px; font-size: 14px;">Pagamento na entrega</div>
                </div>
                <div class="card__selected__option__button" *ngIf="this.company.configs.onlineAccept == 1">
                  <button (click)="changeButton()">Alterar</button>
                </div>
              </div>
        </ng-container>
        
        <ng-container *ngIf="this.selectedOption.value == 0">
          <ng-container *ngIf=" this.methodReceipt.value == 3 ||  this.methodReceipt.value == 2">
            <div class="card__selected__option"  style="height: 47px;">
              <div class="card__selected__option__title">
                <div class="card__choice__option__title__icon">
                  <i class="fa-solid fa-store" style="font-size: 16px;"></i>
                </div>
                <div style="padding-left: 10px; font-size: 14px;">Pagamento no balcão</div>
              </div>
              <div class="card__selected__option__button" *ngIf="this.company.configs.onlineAccept == 1">
                <button (click)="changeButton()">Alterar</button>
              </div>
            </div>
          </ng-container>
        </ng-container>

    </div>
</div>
