import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';

@Component({
  selector: 'app-payment-form-card',
  templateUrl: './payment-form-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './payment-form-card.component.css']
})
export class PaymentFormCardComponent implements OnInit {
  step = false;
  @Input() methodReceipt: { checked: boolean, value: number } = {checked: false, value: 0}
  @Input() typeMethodPayment: { checked: boolean, value: number } = {checked: false, value: 0}
  @Output() optionSelected: EventEmitter<{ checked: boolean, value: number, name: string, id_form: string}> = new EventEmitter<{ checked: boolean, value: number, name: string, id_form: string}>();

  selectedOption: { checked: boolean, value: number, name: string, id_form: string, icon: number} = { checked: false, value: 0, name: '', id_form: '', icon: 1};
  company!: Company;

  constructor(private connection: ConnectionService){}

  ngOnInit(): void {
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })
    if(this.selectedOption.checked == false)
    {
      this.step = true;
    }
  }

  onCheckboxChange(data: { checked: boolean, value: any }, name: string, id_form: string, icon: number) {
    this.selectedOption = { checked: data.checked, value: data.value, name: name, id_form: id_form, icon: icon};
    this.optionSelected.emit({ checked: data.checked, value: data.value, name: name, id_form: id_form});
  }

  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0
    this.selectedOption.name = '';
    this.selectedOption.icon = 1;
    this.optionSelected.emit({ checked: false, value: 0, name: '', id_form: ''});
  }
}
