import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrl: './copy-button.component.css'
})
export class CopyButtonComponent {
  @Input() valueInput: string | undefined = ''

  constructor(private toastr: ToastrService){}


  copyText(text: string){
    const inputElement = document.createElement('input');
    inputElement.value = text;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
    this.toastr.success('', 'Copiado', { positionClass: 'toast-top-right', timeOut: 1000});
  }
}
