import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[currencyBRL]'
})
export class FormatationCurrencyDirective implements OnInit{
  @Input() value!: number;
  @Output() valueForm: EventEmitter<string> = new EventEmitter<string>();

  //currencyBRL [value]="distance.value" (valueForm)="distance.value = $event"

  constructor(
    private el: ElementRef, 
    private control: NgControl) { }

  ngOnInit(): void {
    if(this.value){
      this.formatValue();
    }

  }

  @HostListener('input', ['$event']) onInput(event: any) {
    const valor = this.el.nativeElement.value;

    const valorNumerico = parseFloat(valor.replace(/[^0-9]/g, ''));

    if (!isNaN(valorNumerico)) {
      const valorFormatado = (valorNumerico / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

      this.control?.control?.setValue(valorFormatado, { emitEvent: false });
    } else {
      // Se não for um número válido, manter o valor original no input
      this.control?.control?.setValue(valor, { emitEvent: false });
    }


  }

  private formatValue(): void {
    const value = this.value.toString();
    const valorNumerico = parseFloat(value.replace(/[^0-9]/g, ''));

    if (!isNaN(valorNumerico)) {
      const valorFormatado = (valorNumerico / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

      this.valueForm.emit(valorFormatado)
    } else {

      this.valueForm.emit(value)
    }
  }

}
