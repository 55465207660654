<ng-container *ngIf="this.order.orderType == 5">
  <div class="order-history" class="order-history" *ngIf="this.order.orderStatus != 0 && this.order.orderStatus != 9 && this.order.orderStatus != 10">
    <div class="box-time">
      <div class="timer">
        <span class="order-history__box__time">{{ order.createdAt | date:'HH:mm' }}</span>
      </div>
      <ng-container *ngIf="this.order.events" >
        <ng-container *ngFor="let event of this.order.events">

          <ng-container *ngIf="event.orderStatus == 2">
            <div class="timer">
              <span class="order-history__box__time">{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="event.orderStatus == 4">
            <div class="timer">
              <span class="order-history__box__time" >{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
          <ng-container  *ngIf="event.orderStatus == 5">
            <div class="timer">
              <span class="order-history__box__time">{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="div-order-history">
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 1 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 1}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
      <div class="order-history__box " [ngClass]="{'active': order.orderStatus >= 2 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 2}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>

      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 4 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 4}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>

      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus == 5 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active-static': order.orderStatus == 5}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="status-name">
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 1}">Enviado</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 2}">Em preparo</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 4}">A caminho</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus == 5}">Finalizado</span>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="this.order.orderType == 2 || this.order.orderType == 3">
  <div class="order-history" *ngIf="this.order.orderStatus != 0 && this.order.orderStatus != 9 && this.order.orderStatus != 10">
    <div class="box-time">
      <div class="timer">
        <span class="order-history__box__time">{{ order.createdAt | date:'HH:mm' }}</span>
      </div>
      <ng-container *ngIf="this.order.events" >
        <ng-container *ngFor="let event of this.order.events">

          <ng-container *ngIf="event.orderStatus == 2">
            <div class="timer">
              <span class="order-history__box__time">{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="event.orderStatus == 3">
            <div class="timer">
              <span class="order-history__box__time" >{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
          <ng-container  *ngIf="event.orderStatus == 5">
            <div class="timer">
              <span class="order-history__box__time">{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="div-order-history">
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 1 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 1}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 2 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 2}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>

      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 3 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 3}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 5 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active-static': order.orderStatus == 5}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="status-name">
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 1}">Enviado</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 2}">Em preparo</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 3}">Pronto</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 5}">Finalizado</span>
      </div>
    </div>
    <div class="massage-warning" *ngIf="this.order.orderStatus == 3" [ngClass]="{'vibration': this.vibration}">
      <div class="warning-icon">
        <i class="fa-regular fa-bell bell-animation"></i>
      </div>
      <span class="text-warningg">Seu pedido está pronto para retirada no balcão!</span>
    </div>
  </div>
</ng-container>

<!-- <ng-container *ngIf="this.order.orderType == 3">
  <div class="order-history" *ngIf="this.order.orderStatus != 5 && this.order.orderStatus != 6">
    <div class="box-time">
      <div class="timer_counter">
        <span class="order-history__box__time">{{ order.createdAt | date:'HH:mm' }}</span>
      </div>
      <ng-container *ngIf="this.order.events" >
        <ng-container *ngFor="let event of this.order.events">

          <ng-container *ngIf="event.orderStatus == 2">
            <div class="timer_counter">
              <span class="order-history__box__time">{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="event.orderStatus == 3">
            <div class="timer_counter">
              <span class="order-history__box__time" >{{ event.createdAt | date: 'HH:mm' }}</span>
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
    </div>
    <div class="div-order-history">
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 1 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 1}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 2 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 2}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>

      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 3 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active': order.orderStatus == 3}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
      <div class="order-history__box" [ngClass]="{'active': order.orderStatus >= 5 }">
        <div class="order-history__box__content">
          <div class="order-history__line">
          </div>
          <div class="order-history__circle">
            <div class="circle-animation" [ngClass]="{'active-static': order.orderStatus == 5}"></div>
          </div>
          <div class="order-history__line">
            <div class="line-animation"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="status-name">
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 1}">Enviado</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 2}">Em preparo</span>
      </div>
      <div class="status">
        <span class="order-history__box__status" [ngClass]="{'active': order.orderStatus >= 3}">Pronto</span>
      </div>
    </div>
    <div class="massage-warning" *ngIf="this.order.orderStatus == 3" [ngClass]="{'vibration': this.vibration}">
      <div class="warning-icon">
        <i class="fa-regular fa-bell bell-animation"></i>
      </div>
      <span class="text-warningg">Seu pedido está pronto para retirada no balcão!</span>
    </div>
  </div>
</ng-container> -->

