import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { MenuComponent } from './views/menu/menu.component';
import { AuthLayoutComponent } from './shared/components/layout/auth-layout/auth-layout.component';
import { UserDataComponent } from './views/user-data/user-data.component';
import { CartComponent } from './views/cart/cart.component';
import { PedidosComponent } from './views/pedidos/pedidos.component';
import { ConsumptionComponent } from './views/consumption/consumption.component';
import { OrderConfirmationComponent } from './views/order-confirmation/order-confirmation.component';
import { OrderDetailsComponent } from './views/order-details/order-details.component';
import { IdentificationGuard } from './guard/identification/identification.guard';
import { AuthenticationComponent } from './shared/components/authentication/authentication.component';
import { verificationGuard } from './guard/verification/verification.guard';
import { verificationCacheGuard } from './guard/verification-cache/verification-cache.guard';
import { redirectGuard } from './guard/redirect/redirect.guard';
import { AddressComponent } from './views/address/address.component';
import { EditProductComponent } from './views/edit-product/edit-product.component';
import { LoadingProcessingOrderComponent } from './shared/components/loading-processing-order/loading-processing-order/loading-processing-order.component';
import { OrderConfirmationCounterComponent } from './views/orderConfirmationCounter/order-confirmation-counter/order-confirmation-counter.component';
import { UserViewComponent } from './views/user-view/user-view.component';
import { FidelityComponent } from './views/fidelity/fidelity.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing/home',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing-page/landing.module').then(m => m.LandingModule)
  },

  {
    path: 'Authentication',
    component: AuthenticationComponent
  },
  {
    path: ':nameCompany',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        component: MenuComponent,
        canActivate: [IdentificationGuard],
      },
      {
        path: 'QrCode/:identification',
        component: MenuComponent,
        canActivate: [verificationGuard],
      },
      {
        path: 'Counter/:identification',
        component: MenuComponent,
        canActivate: [verificationGuard]
      },
      {
        path: 'B',
        component: MenuComponent,
        canActivate: [verificationCacheGuard],
      },
      {
        path: 'P',
        component: MenuComponent,
        canActivate: [verificationCacheGuard],
      },
      {
        path: 'product-detail/:id_product',
        component: ProductDetailComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'edit-product/:id_product',
        component: EditProductComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'order-details/:id_order',
        component: OrderDetailsComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'carrinho',
        component: CartComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'consumption',
        component: ConsumptionComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'user',
        component: UserViewComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'fidelity',
        component: FidelityComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'user-data',
        component: UserDataComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'address',
        component: AddressComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'pedidos',
        component: PedidosComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'order-confirmation',
        component: OrderConfirmationComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'order-confirmation-counter',
        component: OrderConfirmationCounterComponent,
        canActivate: [redirectGuard]
      },
      {
        path: 'processing',
        component: LoadingProcessingOrderComponent,
        canActivate: [redirectGuard]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  })],
  exports: [RouterModule],
  providers:[IdentificationGuard, verificationGuard, verificationCacheGuard, redirectGuard]
})


export class AppRoutingModule{}
