import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'checkbox-radius',
  templateUrl: './checkbox-radius.component.html',
  styleUrl: './checkbox-radius.component.css'
})
export class CheckboxRadiusComponent{
  @Input() checkboxData: { checked: boolean, value: number | string | any } = { checked: false, value: 0 };
  //@Output() checkboxChange: EventEmitter<{ checked: boolean, value: number | string }> = new EventEmitter<{ checked: boolean, value: | string }>();
  @Output() checkboxChange: EventEmitter<{ checked: boolean, value: number | string | any }> = new EventEmitter<{ checked: boolean, value: string | number | any }>();


  onCheckboxChange() {
      this.checkboxChange.emit({ checked: this.checkboxData.checked, value: this.checkboxData.value});
  }
}
