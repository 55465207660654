<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="navegation-header">
  <div class="navegation-header__icon" (click)="goback()">
    <i class="fa fa-chevron-left" aria-hidden="true"></i>
  </div>
  <div class="navegation-header__tittle">
    <h3>Detalhes do pedido</h3>
  </div>
</div>
<div style="max-width: 800px; margin: 0 auto 60px auto; padding-bottom: 60px;">
  <ng-container *ngIf="this.order">
    <div class="order-details-header">
      <div class="order-details-header__content">
        <div>
          <span class="order-number">Pedido #{{ this.numberOrder }}</span>
          <span class="order-date-time">Em {{this.dateOrder}}</span>
        </div>
        <div [ngSwitch]="this.order.orderStatus">
          <div *ngSwitchCase="1" class="button-status" style="background-color: var(--primary-color); border-radius: 5px;">
            <span class="status-order">Novo</span>
          </div>
          <div *ngSwitchCase="2" class="button-status" style="background-color: #c96b0a; border-radius: 5px;">
            <span class="status-order">Em preparo</span>
          </div>
          <div *ngSwitchCase="3" class="button-status" style="background-color: green; border-radius: 5px;">
            <span class="status-order">Pronto</span>
          </div>
          <div *ngSwitchCase="4" class="button-status" style="background-color: green; border-radius: 5px;">
            <span class="status-order">A caminho</span>
          </div>
          <div *ngSwitchCase="5" class="button-status" style="background-color: #a9a9a9; border-radius: 5px; border: 1px solid #a1a1a1;">
            <span class="status-order">Finalizado</span>
          </div>
          <div *ngSwitchCase="0" class="button-status" style="background-color: #d70505; border-radius: 5px;">
            <span class="status-order">Cancelado</span>
          </div>
          <div *ngSwitchCase="9" class="button-status" style="background-color: #fda603; border-radius: 5px;">
            <span class="status-order">Aguardando pagamento</span>
          </div>
          <div class="button-status" *ngSwitchCase="10" style="background-color: #d70505; border-radius: 5px;">
            <span class="status-order">Cancelado/Negado</span>
          </div>
          <div class="button-status" *ngSwitchCase="11" style="background-color: #c96b0a; border-radius: 5px;">
            <span class="status-order">Aguardando Reembolso</span>
          </div>
          <div class="button-status" *ngSwitchCase="12" style="background-color: green; border-radius: 5px;">
            <span class="status-order">Reembolso concluido</span>
          </div>
        </div>
      </div>
    </div>
    <app-time-await [typeOrder]="this.order.orderType" [visualization]="1" [createdAtOrder]="this.order.createdAt" *ngIf="this.order.orderStatus == 1 || this.order.orderStatus == 2 || this.order.orderStatus == 3 || this.order.orderStatus == 4"></app-time-await>
    <div style="padding-left: 1rem; padding-right: 1rem;">
      <app-progress-bar [order]="order" *ngIf="this.order.orderStatus != 5 && this.order.orderStatus != 0 && this.order.orderStatus != 10 && this.order.orderStatus != 11 && this.order.orderStatus != 12"></app-progress-bar>
    </div>
    <app-input-copy-pix *ngIf="order.orderStatus == 9" [valueKey]="this.order.paymentForm[0].pixCode" [dateTimeTransation]="this.order.createdAt"></app-input-copy-pix>
    <app-history-events [order]="order"></app-history-events>


    <div class="box">
      <div class="box-tittle">
        <div>Pedido</div>
      </div>
      <div class="box-body">
        <div class="body-order">
          <div class="body-order__order" *ngFor="let item of items.items; let i = index">
            <div class="d-flex" style="font-size: 13px; gap: 5px; justify-content: space-between; margin-bottom: 10px;">
              <div class="d-flex">
                <img class="img-item" [src]="item.product.image_prod" />
                <div class="d-flex" style="flex-direction: column;">
                  <div class="d-flex">
                    <div style="margin-right: 3px;">{{ item.quantity }}x</div>
                    <span>{{ item.product.name }}</span>
                  </div>

                  <div style="padding-left: 10px;">
                    <div>
                      <ng-container *ngFor="let additionais of item.subitems">
                        <ng-container *ngIf="additionais.product.flavor == 1">
                          <ng-container *ngIf="item.subitems.length == 1">
                            <div style="color: gray;">
                              <span style="font-size: 12px;">1x</span><span style="font-size: 11px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="item.subitems.length != 1">
                            <div style="color: gray;">
                              <span style="font-size: 12px;">{{ additionais.quantitySub }}/{{ item.flavorsCount }}</span><span style="font-size: 11px;"> {{ additionais.product.name }}</span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngFor="let additionais of item.subitems">
                        <ng-container *ngIf="additionais.product.flavor == 0">
                          <div style="color: gray;">
                            <span style="font-size: 12px;">{{ additionais.quantitySub }}x</span><span style="font-size: 11px;"> {{ additionais.product.name }}</span>
                          </div>
                        </ng-container>
                      </ng-container>

                    </div>
                  </div>
                  <span *ngIf="item.observations != ''" style="color: gray; max-width: 200px; word-wrap: break-word;">Obs: {{ item.observations }}</span>
                </div>
              </div>

              <div>
                <span class="price-item">{{ item.totalPrice | currency:'BRL' }}</span>
              </div>

            </div>
          </div>
          <span style="color: gray; font-size: 14px; word-wrap: break-word; width: 100%;" *ngIf="this.order.orderObservation != ''">Obs: {{ this.order.orderObservation }}</span>
        </div>
      </div>
    </div>

    <div class="box" *ngIf="this.order.orderType == 5">
      <div class="box-tittle">
        <div>Endereço de entrega</div>
      </div>
      <div class="box-body">
        <div class="body-order">
          <div class="body-order__order">
            <div class="address_content">
              <div>{{this.addressDelivery.street}}, {{this.addressDelivery.number}}</div>
              <div>{{ this.addressDelivery.neighborhood }}, {{ this.addressDelivery.city }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" *ngIf="this.order.orderType == 3 || this.order.orderType == 2">
      <div class="box-tittle">
        <div>Tipo do pedido</div>
      </div>
      <div class="box-body">
        <div class="body-order">
            <div class="content-receipt">
              <div class="icon-local"><i class="fa-solid fa-route" style="font-size: 17px; color: var(--primary-color);"></i></div>
              <div style="font-size: 14px; color: #575757; padding-left: 10px;" *ngIf="this.order.orderType == 3">Retirar no local</div>
              <div style="font-size: 14px; color: #575757; padding-left: 10px;" *ngIf="this.order.orderType == 2">Retirar no balcão</div>
            </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="box-tittle">
        <div>Tipo de pagamento</div>
      </div>
      <div class="box-body">
        <div class="form-payment__body">
          <div class="form-payment-option">
            <div style="display: flex; align-items: center;" *ngIf="this.order.onlinePay == 0">
              <div class="form-payment-option__icon">
                <i class="fa-solid fa-motorcycle" style="font-size: 20px;" *ngIf="this.order.orderType == 5"></i>
                <!-- <img src="assets/icons/counter.png" style="width: 22px;" *ngIf="this.order.orderType == 2 || this.order.orderType == 3"/> -->
                <i class="fa-solid fa-store" style="font-size: 16px;" *ngIf="this.order.orderType == 2 || this.order.orderType == 3"></i>
              </div>
              <div class="form-payment-option__tittle" style="font-size: 14px; color: #575757;" *ngIf="this.order.orderType == 5">
                Pagamento na entrega
              </div>
              <div class="form-payment-option__tittle" style="font-size: 14px; color: #575757;" *ngIf="this.order.orderType == 2 || this.order.orderType == 3">
                Pagamento no balcão
              </div>
            </div>
            <div style="display: flex; align-items: center;" *ngIf="this.order.onlinePay == 1">
              <div class="form-payment-option__icon">
                <i class="fa-solid fa-mobile-screen-button" style="font-size: 20px;"></i>
              </div>
              <div class="form-payment-option__tittle" style="font-size: 14px; color: #575757;">
                Pagamento no app
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="box-tittle">
        <div>Forma de pagamento</div>
      </div>
      <div class="box-body">
        <div class="form-payment__body">
          <ng-container *ngFor="let form of this.order.paymentForm">
            <div class="form-payment-option" [ngSwitch]="form.paymentForm">
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center;">
                  <div class="form-payment-option__icon" [ngSwitch]="form.icon">
                    <i *ngSwitchCase="1"><img src="../../../../assets/icons/method-payment/dinheiro.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="2"><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="3"><img src="../../../../assets/icons/method-payment/generic-card-2.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="4"><img src="../../../../assets/icons/method-payment/mastercard-card.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="5"><img src="../../../../assets/icons/method-payment/visa-card.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="6"><img src="../../../../assets/icons/method-payment/pix.png"  style="width: 25px;"/></i>
                    <i *ngSwitchCase="7"><img src="../../../../assets/icons/method-payment/american-express.png"  style="width: 25px;"/></i>
                    <i *ngSwitchDefault><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                  </div>
                  <div class="form-payment-option__tittle" style="font-size: 14px; color: #575757;">
                    {{form.paymentName}}
                  </div>
                </div>
                <div class="obs_ChangeFor" *ngIf="this.order.paymentForm[0].changeFor != '0.00'">*Obs: troco para R${{ this.order.paymentForm[0].changeFor}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="price-total-order">
      <div class="total">
        <div class="summary-total">
          <div class="total-value">
            <div class="total-span">Subtotal</div>
            <div class="total-span" *ngIf="this.discount != 0">{{ this.totalOrderProducts + this.discount| currency:'BRL' }}</div>
            <div class="total-span" *ngIf="this.discount == 0">{{ this.totalOrderProducts | currency:'BRL' }}</div>
          </div>
          <div class="total-value" *ngIf="this.order.orderType == 5">
            <div class="total-span">Taxa de entrega</div>
            <div class="total-span" *ngIf="this.order.deliveryFee != '0.00'">{{ this.order.deliveryFee | currency:'BRL' }}</div>
            <div class="total-span" *ngIf="this.order.deliveryFee == '0.00'" style="color: green;">Grátis</div>
          </div>
          <ng-container *ngIf="this.discount != 0">
            <div class="total-value" *ngIf="this.order.discounts[0].discountValue != '0'">
              <div class="total-span">Desconto:</div>
              <div class="total-span" style="color: green;">-{{ this.order.discounts[0].discountValue | currency:'BRL' }}</div>
            </div>
          </ng-container>

        </div>
        <div class="div-total-value">
          <div class="content-total-value">
            <div>Total</div>
            <div>{{ this.order.orderPrice | currency:'BRL' }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <app-feedback></app-feedback> -->
  </ng-container>
</div>
<!-- <app-bottom-menu></app-bottom-menu> -->
