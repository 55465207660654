<div class="card__order" *ngIf="!this.selectedOption.checked">
    <div class="card__title" [ngClass]="{'active-title': this.step}">
      <div style="font-size: 14px;">Selecione o endereço</div>
    </div>
    <div class="card__choice__body" [ngClass]="{'active-body': this.step}">
        <div class="card__choice__body__address">
            <div class="card__choice__option__address" *ngFor="let address of this.user.address; let i = index">
                <div style="margin-bottom: 5px; font-size: 14px;">{{ address.identifier }}</div>
                <div class="address__option">
                    <div class="address__option__content">
                        <div>{{address.street}}, {{address.number}}</div>
                        <div>{{ address.neighborhood }}, {{ address.city }}</div>
                        <div class="address__option__content__buttons">
                            <button class="button-delete" (click)="this.showModalDelete(address.id_address)">Excluir</button>
                            <button class="button-edit" (click)="this.editAddress(address.id_address, address)">Editar</button>
                        </div>
                    </div>
                    <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: address.id_address }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
                </div>
            </div>
        </div>
        <div class="address_button">
            <button (click)="registerAddress()">+ Adicionar endereço</button>
          </div>
    </div>
</div>
<app-card-loading [openCard]="showLoading" [labelPerson]="'Aguarde...'"></app-card-loading>


<div class="card__order" *ngIf="this.selectedOption.checked">
    <div class="card__title">
      <div style="font-size: 14px;">Endereço</div>
    </div>
    <div class="card__choice__body">

        <div class="card__choice__body__address" style="flex-direction: row; justify-content: space-between;">
            <div class="card__choice__option__address" *ngIf="this.addressOption">
                <div style="margin-bottom: 5px; font-size: 14px;">{{ addressOption.identifier }}</div>
                <div class="address__option">
                    <div class="address__option__content">
                        <div>{{addressOption.street}}, {{addressOption.number}}</div>
                        <div>{{ addressOption.neighborhood }}, {{ addressOption.city }}</div>
                    </div>
                </div>
            </div>
            <div class="card__selected__option__button">
                <button (click)="changeButton()">Alterar</button>
            </div>
        </div>
        <app-time-await [typeOrder]="5" [visualization]="0"></app-time-await>
    </div>
</div>


<!--Modal de deletar-->
<div class="modal" [ngClass]="{'show': openModalDelete}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Excluir endereço</h1>
        </div>
        <div class="modal-body">
          <span style="text-align: center;">Tem certeza que deseja excluir este endereço?</span>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-edit" (click)="openModalDelete = false">Não</button>
          <button type="button" class="btn btn-confirm" (click)="deleteAddress()">Sim</button>
        </div>
      </div>
    </div>
  </div>
