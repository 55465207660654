<div class="card" [ngClass]="{'show': openCard}">
  <div class="card-content">
    <div class="loader">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <!-- <i class="fa-solid fa-location-dot"></i> -->
    </div>
    <div class="span">
      {{ labelPerson }}
    </div>
  </div>
</div>
