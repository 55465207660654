import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {
  images = [
    'assets/slides/2.jpg',
    'assets/slides/3.jpg',
    'assets/slides/4.jpg',
    'assets/slides/5.jpg',
    'assets/slides/6.jpg',
    'assets/slides/7.jpg',
  ];
constructor(){}


ngOnInit(): void {

}


}
