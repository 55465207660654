import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[not-emoji]'
})
export class notEmojisDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: any) {
      const valor = this.el.nativeElement.value;
  
      // Remove emojis do valor
    //   const valorSemEmojis = valor.replace(/[^a-zA-Z0-9,\s.]/g, '');
      const valorSemEmojis = valor.replace(/[^\w,\s.áéíóúÁÉÍÓÚâêîôÂÊÎÔãõÃÕçÇ]/g, '');
  
      // Define o valor do input como o valor sem emojis
      this.el.nativeElement.value = valorSemEmojis;
    }

}
