import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, ChangeDetectionStrategy} from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CartComponent } from '../../../views/cart/cart.component';
import { MenuComponent } from 'src/app/views/menu/menu.component';
import { Company, ConnectionService} from '../../services/Connection/connection.service';
import { CacheService } from '../../services/Cache/cache.service';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { CartService } from '../../services/Cart/cart.service';
import { typeInterface } from 'src/app/Interfaces/company';


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable({
  providedIn: 'root'
})

export class TopMenuComponent implements OnInit, AfterViewInit{

  //#region Variaveis de elementos HTML
    @ViewChild('buttonSearch') buttonSeach!: ElementRef;
    @ViewChild('divInput', {static: false}) divInput!: ElementRef;
    @ViewChild('input') input!: ElementRef;
    @ViewChild('imgCompany') imgCompany!: ElementRef;
    @ViewChild('divIcon') divIcon!: ElementRef;
    @ViewChild('inputDesktop') inputDesktop!: ElementRef;
    @ViewChild('divBar') divBar!: ElementRef;
    @ViewChild('topMenu') topMenu!: ElementRef;
  //#endregion

  //#region Variaveis locais

  private typeParam!: typeInterface;
  nameCompany: string = '';

    alterationMenu = false
    showMenu: boolean = true;
    isMenuExpanded = true;
    scrollTopPosition: number = 0;

    img!: string;
    company!: Company
    nameEmpresa: string = "";
    verificationTable!: boolean;
    userConfirmation = this.cacheService.getItemLocal('userConfirmation');

    counterCart: number = 0;
    darkTheme = this.cacheService.getItemLocal('dark-theme')

    toggleTheme = this.darkTheme;
  //#endregion

  //#region Inicializadores

    constructor(
                private changeDetectorRef: ChangeDetectorRef,
                private router: Router,
                private connection: ConnectionService,
                private authLayout: AuthLayoutComponent,
                private cacheService: CacheService,
                private cartService: CartService
                ){

    }

    //Chama as funções assim que o component é inicializado
    ngOnInit(): void {

      this.connection.nameCompany$.subscribe(newNameCompany => {
        this.nameCompany = newNameCompany
      })

      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam;
      })

      this.circleRedCount()
      if(this.typeParam.type == 1 || this.typeParam.type == 2){
        this.verificationTable = true
      }
      else{
        this.verificationTable = false
      }
      }

    //Chama as funções assim que todo o DOM da aplicação foi carregado
    ngAfterViewInit(){

      this.connection.company$.subscribe((company) =>{
        if(company){
        this.company = company;
        this.img = this.company.company.logo;
        this.nameEmpresa = this.company.company.companyName
        }
      })
      this.circleRedCount()
      this.changeDetectorRef.detectChanges();
    }

  //#endregion

    circleRedCount(){
      this.cartService.counter$.subscribe(newCount => {
        this.counterCart = newCount;
      });
      this.changeDetectorRef.detectChanges();
    }

  //#region Funções de estilo (Style)


    //adiciona classe dark-theme para mudar o tema
    addclass() {
      this.authLayout.addclass();
      this.toggleTheme = !this.toggleTheme;
      this.cacheService.setItemLocal('dark-theme', this.toggleTheme)
    }

  includeDivBar(){
    this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(window.innerWidth <= 480)
    {
      if(this.scrollTopPosition >= 124)
      {
        this.divBar.nativeElement.style.display = 'block'
      }
      else{
        this.divBar.nativeElement.style.display = 'none'
      }
    }
    else{}
  }


  //#endregion

  //#region Funções de lógica (Logic)
  //#endregion













}
