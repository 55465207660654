import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTimeFormat]'
})
export class TimeFormatDirective implements OnChanges {

  @Input('appTimeFormat') dateStr!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateStr']) {
      const formattedTime = this.formatTime(this.dateStr);
      this.renderer.setProperty(this.el.nativeElement, 'innerText', formattedTime);
    }
  }

  private formatTime(dateStr: string): string {
    const date = new Date(dateStr);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
