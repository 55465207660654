import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { Cliente } from 'src/app/shared/services/User/user.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class UserViewComponent implements OnInit{

  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @ViewChild('inputName') inputName!: ElementRef;

  existingUser = false;
  existingUserData: any;
  openLogOut: boolean = false;
  user!: Cliente;
  alterationButton: boolean = false;
  nameCompany: string = '';
  userConfirmation = false;
  name: string = '';
  phoneNumber: string = '';
  id_customer: string = '';
  document: string = '';
  alterationUser = false;
  lengthInputName: any;
  lengthInputPhone = false;
  company!: Company;
  corretFormatPhoneNumber: any;
  phoneNumberPattern = /^\(\d{2}\)\d{5}\-\d{4}$/;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService){

  }

  ngOnInit(): void {
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      if(!this.fidelityGet)
      {
        this.getFidelity();
      }
    })
    this.connection.user$.subscribe((User) =>{
      if(User)
      {
        this.userConfirmation = true;
        this.user = User
        if(!this.fidelityGet)
        {
          this.getFidelity();
        }

      }
      else if(this.cacheService.getItemLocal('user_key'))
      {
        this.userConfirmation = true;
      }
      else{
        this.userConfirmation = false
      }
    })
  }

  navigateOrders(){
    this.router.navigate([this.nameCompany, 'pedidos']);
  }


//#region Log in User

  getFidelity(){
    if(this.company){
      if(this.user){
        this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
          (data) =>{
            this.fidelityUser = data;
            this.fidelityGet = true
            console.log(this.fidelityUser);
          },
          (error) =>{
            console.log(error);
          }
        )
      }
    }

  }

  //Assim que confirmado ele monta os objetos necessarios com os valores dos campos
  submitForm(){
    let user: any

    if(this.existingUser){
      
      if(this.existingUserData.response.customer.name != this.name || this.existingUserData.response.customer.phone != this.phoneNumber)
        {
          this.alterationUser = true;
          if(this.id_customer != this.existingUserData.response.customer.id_customer){
            this.id_customer = this.existingUserData.response.customer.id_customer
          }

        }
    }

    const cliente: Cliente = {
      name: this.name,
      phone: this.phoneNumber,
      document: this.document,
      id_customer: this.id_customer
    }

    if(this.existingUser == false)
    {
      if(this.correctNumberPhone(this.phoneNumber))
      {

        cliente.phone = this.phoneNumber;

        this.apiService.postUsuario(cliente).subscribe(
          (data) => {
            console.log('Usuario cadastrado com sucesso:', data);

            if(user){
              if(data.response.Customer.id_customer != user.id_customer){
                this.cacheService.removeItemLocal('userAddress')
                this.cacheService.setItemLocal('user_key', data.response.Customer)
              }
            }

            this.connection.updateUser(data.response.Customer)
            this.cacheService.setItemLocal('user_key', data.response.Customer)
            this.cacheService.setItemLocal('userConfirmation', true)

            if(this.cacheService.getItemLocal('userAddress'))
            {
              this.cacheService.removeItemLocal('userAddress')
            }


          },
          (error) => {
            console.error('Erro ao cadastrar usuario:', error);
            this.apiService.getUsuario(this.phoneNumber).subscribe(
              (data) => {
                this.handleSuccessfulResponse(data)
              },
              (error) => {
                this.handleError(error)
              }
            )
          }
        );
      }

    }
    else{
      if(this.correctNumberPhone(this.phoneNumber))
      {
        cliente.phone = this.phoneNumber;
        this.pathUser();
      }

    }

  }

  //Acionado toda vez que uma letra é digitada no input do nome
  onInputChangeName(event:any)
  {
    const length = event.target.value.length
    if(length >= 3 )
    {
      this.lengthInputName = true;
    }
    else{
      this.lengthInputName = false;
    }

  }

  //Verifica se o numero esta realmente correto antes de  fazer qualquer cadastro
  correctNumberPhone(phone: string){
    let rawValue = ''
    let formatted = '';

    rawValue = phone.replace(/\D/g, '');

    if(rawValue != '')
    {
      const firstPart = rawValue.substring(0, 2)
      const secondPart = rawValue.substring(2, 7)
      const treetPart = rawValue.substring(7, 11)


      if(rawValue.length >= 1 && rawValue.length < 2){
        formatted = `(${firstPart}`
      }
      else if(rawValue.length >= 2 && rawValue.length < 7){
        formatted = `(${firstPart})`
      }
      else if(rawValue.length >= 7)
      {
        formatted = `(${firstPart})${secondPart}-${treetPart}`
      }

    }
    else{
      formatted = phone;
    }

    this.phoneNumber = formatted;
    // console.log('O numero foi formatado antes de ser enviado para base:', this.phoneNumber)
    return true;

  }

  //Chamada toda vez que é digitado um numero, se o numero telefone for maior igual a 14 ele faz os requests
  onInputChange(event: any){
    const newValue = event.target.value;

    if(newValue.length > 13)
    {
      this.lengthInputPhone = true;
    }
    else{
      this.lengthInputPhone = false;
    }

    let rawValue = ''
    let formatted = ''

    rawValue = newValue.replace(/\D/g, '');

    if(rawValue != '')
    {
      const firstPart = rawValue.substring(0, 2)
      const secondPart = rawValue.substring(2, 7)
      const treetPart = rawValue.substring(7, 11)


      if(rawValue.length >= 1 && rawValue.length < 2){
        formatted = `(${firstPart}`
      }
      else if(rawValue.length >= 2 && rawValue.length < 7){
        formatted = `(${firstPart})${secondPart}`
      }
      else if(rawValue.length >= 7)
      {
        formatted = `(${firstPart})${secondPart}-${treetPart}`
      }

    }
    else{
      this.name = '';
      this.lengthInputName = undefined;
      formatted = rawValue
    }

    this.phoneNumber = formatted;

    if(rawValue != '')
    {
      this.corretFormatPhoneNumber = this.phoneNumberPattern.test(this.phoneNumber)
    }
    else{
      this.corretFormatPhoneNumber = undefined
    }


    if(this.phoneNumber.length === 14){
      this.apiService.getUsuario(this.phoneNumber).subscribe(
        (data) => {
          this.handleSuccessfulResponse(data)
          this.alterationButton = true;
        },
        (error) => {
          console.log('Usuario não cadastrado');
          this.handleError(error)
          this.alterationButton = false;
        }
      )
    }
  }

  // Função para lidar com a resposta bem-sucedida
  private handleSuccessfulResponse(data: any): void {
    this.name = data.response.customer.name;
    this.document = data.response.customer.document;
    this.id_customer = data.response.customer.id_customer;

    this.existingUser = true;
    this.lengthInputName = true;
    this.lengthInputPhone = true;

    this.existingUserData = data;

    this.inputPhone.nativeElement.blur();
  }

  pathUser(){
    if(this.alterationUser){
      this.apiService.patchUser(this.id_customer, this.name, this.phoneNumber).subscribe(
        (data) =>{
          this.cacheService.setItemLocal('user_key', data.response)
          this.connection.updateUser(data.response)
          this.cacheService.setItemLocal('userConfirmation', true)

        },
        (error) =>{
            console.log('Erro ao editar usuario:')
        })
    }
    else{
      const user = this.cacheService.getItemLocal('user_key');

      if (user) {
        if (this.id_customer !== user.id_customer) {
          this.cacheService.removeItemLocal('userAddress');
          this.cacheService.setItemLocal('user_key', this.existingUserData.response.customer);
        }
      } else {
        this.cacheService.setItemLocal('user_key', this.existingUserData.response.customer);
      }

      this.connection.updateUser(this.existingUserData.response.customer);

      this.cacheService.setItemLocal('userConfirmation', true)

    }
  }

  // Função para lidar com erros
  private handleError(error: any): void {

    this.name = '';
    this.existingUser = false;
    if(this.name.length >= 3)
    {
      this.lengthInputName = true;
    }
    else{
      this.lengthInputName = false;
    }

    this.inputPhone.nativeElement.blur();
    this.inputName.nativeElement.focus();
  }

//#endregion

  disconnectUser(){
    this.openLogOut = false;
    this.cacheService.removeItemLocal('userAddress')
    this.cacheService.removeItemLocal('userConfirmation');
    this.cacheService.removeItemLocal('user_key');
    let clientEmpty!: Cliente;
    this.connection.updateUser(clientEmpty)
  }

  navigateFidelity(){
    this.router.navigate([this.nameCompany, 'fidelity']);
  }


}
