import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ConnectionService } from '../../shared/services/Connection/connection.service';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { AuthLayoutComponent } from '../../shared/components/layout/auth-layout/auth-layout.component';
import { MenuComponent } from '../../views/menu/menu.component';
import { ApiService } from '../../shared/services/API/api.service';

@Injectable({
  providedIn: 'root',
})
export class IdentificationGuard implements CanActivate {

  private pageReloaded!: boolean;
  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService, private authLayout: AuthLayoutComponent) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,): boolean {

    const fullUrl = state.url;
    const nameCompany = route.params['nameCompany']

    if(fullUrl == "/")
    {
      let name = this.cacheService.getItemLocal('nameCompany');
      let type = this.cacheService.getItemSession('typeParam')
      const number = this.cacheService.getItemSession('numberParam');

    //#region responsavel por resgatar o nome da empresa

      this.connection.nameCompanySubject.next(nameCompany)

    //#endregion

      if(!name)
      {
        name = '';

      }
      if(!type)
      {
        type = '';
      }

      if(name == '')
      {
        // return false
        this.router.navigate(['/landing/home']);
      }
      else{

        if(type == '')
        {
          this.router.navigate([name]);
        }
        else{
          this.router.navigate([name, type, number]);
        }

      }
    }
    else{
      this.connection.reload$.subscribe((data) =>{
        this.pageReloaded = data;
      })
      this.connection.nameCompanySubject.next(nameCompany)

      if(!this.pageReloaded)
      {
        this.cacheService.clearCacheTimeout('qrCode_key')
        this.cacheService.clearCacheTimeout('counter_key')
      }

    }

    return true
  }
}
