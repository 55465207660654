import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/API/api.service';
import { ConnectionService } from '../../services/Connection/connection.service';
import { Observable } from 'rxjs';
import { Coupon } from 'src/app/Interfaces/coupon';



@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrl: './cupom.component.css'
})
export class CupomComponent implements OnInit{
  @Input() orderPrice: any;
  @Input() productsOrder: any;
  @Input() id_customer: any;
  @Input() typeOrder!: number;
  @Output() coupon: EventEmitter<{ check: boolean, coupon: Coupon}> = new EventEmitter<{ check: boolean, coupon: Coupon}>();
  id_company!: number;
  dayOfWeek!: number;
  cupomDigit: string = ''
  cupomEmpty!: Coupon;
  minValue = false
  stringCoupomEmpty = false

  coupomNotValid!: Coupon;
  couponSelected!: Coupon;
  cupomselecionado = false;
  coupomNotFreeFee: boolean = false;

  constructor(private apiService: ApiService, private connection: ConnectionService){}

  ngOnInit(): void {
    this.connection.company$.subscribe((newCompany) =>{
      this.id_company = newCompany.company.id_company
    })
    this.getDay();
  }


  verifiCoupom(cupom: string){

    if(cupom != ''){
      this.apiService.VerifiCoupon(cupom, this.id_company, this.dayOfWeek, this.productsOrder, this.id_customer).subscribe(
        (data) =>{
          if(!data.empty){
  
            if(!data.expired){


              if(data.deliveryFree){

                if(this.typeOrder == 5){

                  if(data.minValue != 0){
  
                    if(this.orderPrice >= data.minValue){
                      this.couponSelected = data;
                      this.cupomselecionado = true;
                      this.EmitEventCoupomSelected(this.couponSelected);
                    }
                    else{
                      this.coupomNotValid = data;
                      this.cupomselecionado = false;
                      this.minValue = true
                    }
                  }
                  else{
                    this.couponSelected = data;
                    this.cupomselecionado = true;
                    this.EmitEventCoupomSelected(this.couponSelected);
                  }

                }
                else{
                  this.coupomNotValid = data;
                  this.cupomselecionado = false;
                  this.coupomNotFreeFee = true;
                }
              }
              else if(!data.deliveryFree){

                if(data.minValue != 0){
  
                  if(this.orderPrice >= data.minValue){
                    this.couponSelected = data;
                    this.cupomselecionado = true;
                    this.EmitEventCoupomSelected(this.couponSelected);
                  }
                  else{
                    this.coupomNotValid = data;
                    this.cupomselecionado = false;
                    this.minValue = true
                  }
                }
                else{
                  this.couponSelected = data;
                  this.cupomselecionado = true;
                  this.EmitEventCoupomSelected(this.couponSelected);
                }

              }
            }
            else{
              this.coupomNotValid = data;
              this.cupomselecionado = false;
            }
          }
          else{
            this.coupomNotValid = data
            this.cupomselecionado = false;
          }
  
        },
        (error) =>{
          console.log(error);
        }
      )
    }
    else{
      this.stringCoupomEmpty = true;
    }


  }

  getDay(){
    this.apiService.getHours().subscribe(
      (data) =>{
        const date = new Date(data.date)
        this.dayOfWeek = date.getDay();
      },
      (error) =>{}
    )
  }

  //Quando o endereço é selecionado
  EmitEventCoupomSelected(cupom: Coupon) {
    this.coupon.emit({ check: true, coupon: cupom});
  }

  //Quando o botão de alterar e acionado
  changeButton(){
    this.cupomselecionado = false;
    this.coupon.emit({ check: false, coupon: this.cupomEmpty});
  }

  checkChange(event: any){
    this.coupomNotValid = this.cupomEmpty;
    this.stringCoupomEmpty = false;
  }

}
