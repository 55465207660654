import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/API/api.service';

@Component({
  selector: 'app-counter-timing',
  templateUrl: './counter-timing.component.html',
  styleUrl: './counter-timing.component.css'
})
export class CounterTimingComponent implements OnInit{
  @Input() dateTimeTransaction: any = '';
  @Input() fontSize: any = '';
  @Output() finalizionCounter: EventEmitter<boolean> = new EventEmitter<boolean>();
  counterMin: number = 0;
  counterSec: number = 0;
  intervalId: any;

  constructor(private apiService: ApiService){}

  ngOnInit(): void {
    this.dateTimeTransaction = new Date(this.dateTimeTransaction);
    this.startCounter();

      setInterval(() =>{
        this.counterMin = 0;
        this.counterSec = 0;
        clearInterval(this.intervalId)
        this.dateTimeTransaction = new Date(this.dateTimeTransaction);
        this.startCounter()
      }, 10000)
  }


  startCounter():void {
    const targetTime = new Date(this.dateTimeTransaction);

    // this.getHourServer()

    // difereça de 5 min desde que o pedido foi criado
    const differential = 5 * 60 * 1000;

    //Adiciona essa diferença a hora do pedido
    const newDate = new Date(targetTime.getTime() + differential);
    
    // Resgata a hora atual
    const currentTime = new Date();
    
    // Calcular a diferença
    const diferencaEmMilissegundos = newDate.getTime() - currentTime.getTime();
    
    // Extrair minutos e segundos da diferença
    const diferencaEmMinutos = Math.floor((diferencaEmMilissegundos % (60 * 60 * 1000)) / (60 * 1000));
    const diferencaEmSegundos = Math.floor((diferencaEmMilissegundos % (60 * 1000)) / 1000);
    
    if(diferencaEmMinutos > 0 && diferencaEmSegundos > 0)
    {
      // console.log("Tempo restante:", diferencaEmMinutos + ":" + diferencaEmSegundos);
    }


    if(diferencaEmMinutos >= 0 && diferencaEmSegundos >= 0)
    {
      this.counterMin =  diferencaEmMinutos;
      this.counterSec = diferencaEmSegundos;
    }
    else{
      this.counterMin = 0;
      this.counterSec = 0;
      this.finalizionCounter.emit(true);
    }

    this.intervalId = setInterval(() =>{
      if(this.counterMin >= 0)
      {
        if(this.counterSec != 0)
        {
          this.counterSec --
        }
        else if(this.counterSec === 0)
        {
          if(this.counterMin === 0)
          {
            clearInterval(this.intervalId);
            this.finalizionCounter.emit(true);
          }
          else{
            this.counterMin --;
            this.counterSec = 59
          }

        }
      }
    }, 1000);



  }
  
}

