import { Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef, ViewChildren, Directive, QueryList, AfterViewInit, ChangeDetectorRef, Injectable, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { MenuComponent } from '../../../views/menu/menu.component';
import {Company, ConnectionService} from '../../services/Connection/connection.service';
import { ScrollService } from '../../services/Scroll/scroll.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { CacheService } from '../../services/Cache/cache.service';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { Categorias } from 'src/app/Interfaces/company';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class SideBarComponent  implements OnInit, AfterViewInit, OnChanges {
  @Output() showModalCompany = new EventEmitter<boolean>();
  @Input() sections!: QueryList<ElementRef>
  @Input() tabelaFiltrada!: Categorias[];
  @Input() filter: string = '';
  @ViewChildren('meulink') meuLink!: QueryList<ElementRef>;

//#region Variaveis de elemento HTML
//#endregion

//#region Variaveis Locais
  // private scrollPositionSubscription!: Subscription;
  // private scrollIndexSubscription!: Subscription;
  currentSectionIndex: number = 0;
  sectionsPosition: number[] = [];
  imgLogo!: string;
  imgBg!: string;
  company!: Company;
  tabela!: Categorias[];
  clicked = false;
  nameCompany: string | null = '';
  verifiScrolling = false;
  cachePosition: number = 0;
  scrollIndex: number = 0;
//#endregion

//#region Inicializadores
  constructor(
    private scrollService: ScrollService,
    private cacheService: CacheService,
    private connection: ConnectionService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    )
    {
      this.scrollService.scrolling$.subscribe((scrolling) =>{
        this.verifiScrolling = scrolling;
      })
      this.connection.company$.subscribe(newCompany =>{
        this.company = newCompany
        if(this.company)
        {
          this.tabela = this.company.categories;
          this.imgLogo = this.company.company.logo;
          this.imgBg = this.company.company.background;
        }

      })
    }

  ngOnChanges(changes: SimpleChanges): void {
    const index = this.findCurrentSectionIndex(window.scrollY);
    setTimeout(() => {
      this.colorBar(index)
    }, 100);
    // if(changes['tabelaFiltrada']){
    //   this.tabelaFiltrada = this.tabelaFiltrada
    // }
  }

  ngOnInit(): void {


  }
  ngAfterViewInit(){
    if(this.verifiScrolling)
    {
      this.scrollIndex = this.scrollService.getScrollIndex()
      this.cachePosition = this.scrollService.getScrollPosition()

        if(this.scrollIndex == 0){
          this.colorBar(this.cacheService.getItemSession('index'))
          this.cacheService.removeItemSession('index')
        }
        else{
          this.colorBar(this.scrollIndex)
          this.cacheService.removeItemSession('index')
        }

        window.scrollTo({
          behavior: 'instant' as ScrollBehavior,
          top: this.cachePosition,
          left: 0
        })
    }
    else{
      setTimeout(() => {
        this.colorBar(0)
      }, 1000);
    }

    setTimeout(() => {
      this.calculateSectionPositions();
    }, 1000);

  }

  ngOnDestroy(): void {
    // const index = this.findCurrentSectionIndex(window.scrollY);
    // this.scrollService.setScrollPosition(window.scrollY);
    // this.scrollService.setScrollIndex(index)
    // this.scrollService.scrollingSubject.next(true)
    // this.cacheService.setItemSession('index', index);
  }
//#endregion

//#region Funções de estilo (Style)

  colorBar(currentSectionIndex: number){

    const navLinks = this.elementRef.nativeElement.querySelectorAll('.menu-side-bar ul li a');

    this.meuLink.forEach(function(link) {
      link.nativeElement.classList.remove('active');
    });
    if(navLinks[currentSectionIndex]){
      this.renderer.addClass(navLinks[currentSectionIndex], 'active')
    }

  }
  sharedEvent(){
    this.showModalCompany.emit(true);
  }

  colorBarClick(event: Event, index: number): void{
    let scrollOffSet = 0;

    this.meuLink.forEach((element: ElementRef) =>{
      element.nativeElement.classList.remove('active')
    });

    // if(window.innerWidth <= 846)
    // {
    //   if(window.innerWidth <= 480)
    //   {
    //     scrollOffSet = 110;
    //   }
    //   else{
    //     scrollOffSet = 250;
    //   }

    // }
    // else{
    //   scrollOffSet = 92;
    // }

    const element = event.target as HTMLElement;

    element.classList.add('active')
    this.clicked = false;
    this.scrollToSection(index)


  }

  findCurrentSectionIndex(currentScrollY: number): number {
    if(this.sectionsPosition.length != 0){
      for (let i = this.sectionsPosition.length - 1; i >= 0; i--) {
        if (currentScrollY >= this.sectionsPosition[i] - 85) {
          return i;
        }
      }
    }
    else{
      let sectionArray: any;
      if(this.sections)
      {
        sectionArray = this.sections.toArray();

        for (let i = sectionArray.length - 1; i >= 0; i--) {
          if (currentScrollY >= sectionArray[i].nativeElement.offsetTop - 85) {
            return i + 1;
          }
        }
      }
    }
    return 0;
  }

  scrollToSection(index: number){
    window.scrollTo({top: this.sectionsPosition[Number(index)], behavior: 'smooth'});
  }

  calculateSectionPositions() {
    // Assuma que você tem uma lista de seções no seu componente
    if(this.sections){
      this.sections.forEach(section => {
        if (section && section.nativeElement) {

          this.sectionsPosition.push(section.nativeElement.offsetTop - 160);
        }
      });
    }

  }

  private isScrolledToBottom(): boolean {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;

    return windowBottom >= docHeight;
  }

  @HostListener('window:scroll', [])
  onScroll(){
    if(!this.clicked)
    {
      const scrollY = window.scrollY;
      const newSectionIndex = this.findCurrentSectionIndex(scrollY);

      if (newSectionIndex !== this.currentSectionIndex) {
        this.currentSectionIndex = newSectionIndex;
        this.colorBar(newSectionIndex);
      }
    }
    if (this.isScrolledToBottom()) {
      const number = this.sections.length - 1;
      this.colorBar(number)
      console.log('Você atingiu o final da página!');
    }



  }
  @HostListener('window:resize', [])
  onResize(){

  }


//#endregion

//#region Funções de lógica (Logic)
//#endregion






}
