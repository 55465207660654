import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subscription, interval, map, switchMap, timer } from 'rxjs';
import { Location } from '@angular/common';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-loading-processing-order',
  templateUrl: './loading-processing-order.component.html',
  styleUrl: './loading-processing-order.component.css'
})
export class LoadingProcessingOrderComponent implements OnInit {
  @Input() startComponent: boolean = false;
  phrases: string[] = ['Enviando pedido para o estabelecimento', 'Estamos preparando tudo para você.', 'Seu pedido está em nossas mãos agora.', 'Aguarde um pouquinho!']
  textExibing: string = ''
  private currentPhraseIndex = 0;
  private nameCompany: string = '';
  // images = [
  //   'assets/animation-icon/burger.png',
  //   'assets/animation-icon/fritas.png',
  // ];

  currentImage: string = 'assets/logo/Gif/animation-logo-pulse.gif';
  currentIndex: number = 0;
  intervalSubscription: Subscription | undefined;

  constructor(private elementRef: ElementRef, private connection: ConnectionService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,){}

  ngOnInit(): void {
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.disablePullToRefresh()

    this.updatePhrase();
    setInterval(() => this.updatePhrase(), 3000);
    this.disableScroll()

    // this.updateImage();
    this.startAutoSlide();
  }

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    //Desabilita o scroll da pagina
    disableScroll(){
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
    }

    //Abilita o scroll da pagina
    enableScroll(){
      this.renderer.removeStyle(this.document.body, 'overflow');
    }


    private updatePhrase() {
      this.textExibing = this.getNextPhrase();
    }

    getNextPhrase(): string {
      this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.phrases.length;
      return this.phrases[this.currentPhraseIndex];
    }

    ngOnDestroy(): void {
      this.enableScroll()
    }




    startAutoSlide() {
      // this.intervalSubscription = interval(3000).subscribe(() => {
      //   this.next();
      // });
    }

    stopAutoSlide() {
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
    }

    // next() {
    //   this.currentIndex = (this.currentIndex + 1) % this.images.length;
    //   this.updateImage();
    // }

    // updateImage() {
    //   this.currentImage = this.images[this.currentIndex];
    // }
}
