import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormatPipe implements PipeTransform {

  transform(value: string): string {
    const parsedDate = parseISO(value);
    return format(parsedDate, 'dd/MM/yyyy');
  }

}
