import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { ConnectionService } from '../../shared/services/Connection/connection.service';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { ApiService } from '../../shared/services/API/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BottomMenuService } from '../../shared/services/bottom-Service/bottom-menu.service';
import { typeInterface } from 'src/app/Interfaces/company';

@Injectable({
  providedIn: 'root',
})
export class verificationCacheGuard implements CanActivate {
  private pageReloaded!: boolean;
  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService, private bottomService: BottomMenuService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const fullUrl = state.url;
    const partUrl = fullUrl.split('/')
    const nameCompany = partUrl[1];
    const typeParam = partUrl[2];
    let id_company!: number;

    this.connection.reload$.subscribe((data) =>{
      this.pageReloaded = data;
    })

    //#region responsavel por resgatar o nome da empresa

      this.connection.nameCompanySubject.next(nameCompany)

    //#endregion

    if(!this.pageReloaded)
    {
      if(typeParam == 'P')
      {
        const qrCode = this.cacheService.getItemLocalTimeout('qrCode_key')
        if(qrCode)
        {
          return new Observable<boolean>((observer) => {
            this.apiService.getAuthentication(qrCode, nameCompany).subscribe(
              (data) => {
                if(data)
                {
                  const authentication = data.response
                  id_company = data.response.id_company
  
                    if(authentication.cardNumber != 0)
                    {
  
                      this.apiService.getOrderConsumption(authentication.cardNumber, 0, id_company).subscribe((response) =>{
                        if(!response.orderEmpty){
                          this.orderSucess(response)
                        }
                        else{
                          this.orderEmpty();
                        }
                      },
                      (error) =>{
                        this.router.navigate(['/Authentication']);
                      })
  
  
                      if(authentication.cardStatus == 20){
                        this.connection.permissionRequestOrderSubject.next(false);
                      }
                      else
                      {
                        this.connection.permissionRequestOrderSubject.next(true);
                      }

                      const type: typeInterface = {
                        type: 2,
                        qrCodeNumber: qrCode,
                        numberParam: authentication.cardNumber.toString()
                      }
                      this.connection.updateType(type)
                    }
                    else
                    {
                      this.apiService.getOrderConsumption(0, authentication.tableNumber, id_company).subscribe((response) =>{
  
                        if(!response.orderEmpty){
                          this.orderSucess(response)
                        }
                        else{
                          this.orderEmpty();
                        }
  
  
                      },
                      (error) =>{
                        this.router.navigate(['/Authentication']);
                      })
  
  
                      if(authentication.tableStatus == 20){
                        this.connection.permissionRequestOrderSubject.next(false);
                      }
                      else{
                        this.connection.permissionRequestOrderSubject.next(true);
                      }

                      const type: typeInterface = {
                        type: 1,
                        qrCodeNumber: qrCode,
                        numberParam: authentication.tableNumber.toString()
                      }
                      this.connection.updateType(type)
                    }
                }
                this.connection.reloadSubject.next(true)
                this.connection.reloadSubject.complete()
                observer.next(true);
                observer.complete();
              },
              (error) => {
                console.error('Erro ao obter autenticação:', error);
                this.router.navigate(['/Authentication']);
                observer.next(false);
                observer.complete();
              }
            );
          });
        }
        else{
          this.router.navigate(['/Authentication']);
          return false
        }
      }
      else{
        const counter = this.cacheService.getItemLocalTimeout('counter_key')
        if(counter)
        {
          return new Observable<boolean>((observer) => {
            const type: typeInterface = {
              type: 3,
              qrCodeNumber: counter,
              numberParam: 0
            }
            this.connection.updateType(type)

            this.connection.reloadSubject.next(true)
            observer.next(true);
            observer.complete();
            return observer
          });
  
        }
        else{
          this.router.navigate(['/Authentication']);
          return false
        }
      }

    }
    else{

      if(typeParam == 'P')
      {
        const qrCode = this.cacheService.getItemLocalTimeout('qrCode_key')
        if(qrCode)
        {
          return new Observable<boolean>((observer) =>{
            this.apiService.getAuthentication(qrCode, nameCompany).subscribe(
              (data) =>{
                if(data){
                  const authentication = data.response
                  id_company = data.response.id_company
          
                  if(authentication.cardNumber != 0)
                  {
                    this.apiService.getOrderConsumption(authentication.cardNumber, 0, id_company).subscribe(
                      (response) =>{
                      if(!response.orderEmpty){
                        this.orderSucess(response)
                      }
                      else{
                        this.orderEmpty();
                      }
          
                    },
                    (error) =>{
                      this.router.navigate(['/Authentication']);
                    })
          
                    if(authentication.cardStatus == 20){
                      this.connection.permissionRequestOrderSubject.next(false);
                    }
                    else{
                      this.connection.permissionRequestOrderSubject.next(true);
                    }

                    const type: typeInterface = {
                      type: 2,
                      qrCodeNumber: qrCode,
                      numberParam: authentication.cardNumber
                    }
                    this.connection.updateType(type)
                  }
                  else
                  {
          
                    this.apiService.getOrderConsumption(0, authentication.tableNumber, id_company).subscribe((response) =>{
                      if(!response.orderEmpty){
                        this.orderSucess(response)
                      }
                      else{
                        this.orderEmpty();
                      }
          
                    },
                    (error) =>{
                      this.router.navigate(['/Authentication']);
                    })
          
                    if(authentication.tableStatus == 20){
                      this.connection.permissionRequestOrderSubject.next(false);
                    }
                    else{
                      this.connection.permissionRequestOrderSubject.next(true);
                    }

                    const type: typeInterface = {
                      type: 1,
                      qrCodeNumber: qrCode,
                      numberParam: authentication.tableNumber
                    }
                    this.connection.updateType(type)
                  }
                }
                // this.connection.numberQrCodeSubject.next(qrCode);
                this.connection.reloadSubject.next(true)
                this.connection.reloadSubject.complete()
                observer.next(true);
                observer.complete();
              },
              (error) =>{
                console.error('Erro ao obter autenticação:', error);
                this.router.navigate(['/Authentication']);
                observer.next(false);
                observer.complete();
              }
            )
          });
        }
        else{
          this.router.navigate(['/Authentication']);
          return false
        }
      }
      else{
        const counter = this.cacheService.getItemLocalTimeout('counter_key')
        if(counter)
        {
          return new Observable<boolean>((observer) => {
            
            const type: typeInterface = {
              type: 3,
              qrCodeNumber: counter,
              numberParam: 0
            }

            this.connection.updateType(type)
            this.connection.reloadSubject.next(true)
            this.connection.reloadSubject.complete()
            observer.next(true);
            observer.complete();
            return observer
          });
  
        }
        else{
          this.router.navigate(['/Authentication']);
          return false
        }
      }

    }
  }

  orderEmpty(){
    this.bottomService.activeButtonWaiterSubject.next(true)
    const session = this.cacheService.getItemLocal('session_id')
    if(session){
      this.router.navigate(['/Authentication']);
    }
  }

  orderSucess(response: any){
    this.cacheService.setItemLocal('number_local', response.response.orders[0].table)
    this.bottomService.activeButtonWaiterSubject.next(false)
    if(response.response.orders.length == 1)
    {
      const session = this.cacheService.getItemLocal('session_id')

      if(session)
      {
        if(session != response.response.orders[0].id_order)
        {
          this.router.navigate(['/Authentication']);
        }
      }
      else{
        this.cacheService.setItemLocal('session_id', response.response.orders[0].id_order)
      }
    }
  }
}
