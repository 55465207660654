<div class="card__order" *ngIf="!this.selectedOption.checked">
    <div class="card__title" [ngClass]="{'active-title': this.step}">
      <div style="font-size: 14px;">Selecione o recebimento</div>
    </div>
    <div class="card__choice__body" [ngClass]="{'active-body': this.step}">
      <div class="card__choice__option">
        <div class="card__choice__option__title">
          <div style="font-size: 14px;">Retirar no local</div>
        </div>
        <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: 3 }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
      </div>
  
      <div class="card__choice__option">
        <div class="card__choice__option__title">
          <div style="font-size: 14px;">Entrega</div>
        </div>
        <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: 5 }" (checkboxChange)="onCheckboxChange($event)"></checkbox-radius>
      </div>
    </div>
</div>

<div class="card__order" *ngIf="this.selectedOption.checked">
    <div class="card__title">
      <div style="font-size: 14px;">Tipo do pedido</div>
    </div>
    <div class="card__selected__body">
        <ng-container *ngIf="this.selectedOption.value == 3">
            <div class="card__selected__option">
                <div class="card__selected__option__title">
                  <div style="font-size: 14px;">Retirar no local</div>
                </div>
                <div class="card__selected__option__button" *ngIf="this.company">
                  <button (click)="changeButton()" *ngIf="this.company.configs.receipt == 0">Alterar</button>
                </div>
            </div>
            <app-time-await [typeOrder]="3" [visualization]="0"></app-time-await>
        </ng-container>
        
        <ng-container *ngIf="this.selectedOption.value == 5">
            <div class="card__selected__option">
                <div class="card__selected__option__title">
                  <div style="font-size: 14px;">Entrega</div>
                </div>
                <div class="card__selected__option__button" *ngIf="this.company">
                  <button (click)="changeButton()" *ngIf="this.company.configs.receipt == 0">Alterar</button>
                </div>
              </div>
        </ng-container>
    </div>
</div>
