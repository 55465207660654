import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/Interfaces/company';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { Cliente } from 'src/app/shared/services/User/user.service';

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrl: './fidelity.component.css'
})
export class FidelityComponent implements OnInit{
  private typeParam!: typeInterface;
  nameCompany: string = '';
  user!: Cliente;
  company!: Company;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  percentageValue: number = 0;
  percentageCount: number = 0;
  constructor(private router: Router,
    private connection: ConnectionService,
    private cacheService: CacheService,
    private apiService: ApiService,
    private cartService: CartService,
    private toastr: ToastrService,
  ){
    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })
  }

  ngOnInit(): void {
    this.connection.fidelity$.subscribe(
      (data) =>{
        if(!data){
          this.getFidelity()
        }
        else{
          this.fidelityUser = data;
        }

    })
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      if(!this.fidelityUser){
        this.getFidelity();
      }

    })
    this.connection.user$.subscribe((User) =>{
      this.user = User;
      if(!this.fidelityUser){
        this.getFidelity();
      }
    },
    () =>{
      if(this.cacheService.getItemLocal('user_key'))
      {
        this.user = this.cacheService.getItemLocal('user_key')
      }
    })

  }

  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
  }

  //tipo fidelidade 1 é por pedido
  //tipo fidelidade 0 é por valor
  getFidelity(){
    if(this.company){
      if(this.user){
        if(!this.fidelityGet)
        {
          this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
            (data) =>{
              console.log(data)
              this.fidelityUser = data;
              this.fidelityGet = true;
              if(this.fidelityUser.fidelityType == 1){
                this.calcPercentageCount();
              }
              else{
                this.calcPercentageValue();
              }
              console.log(this.fidelityUser);
            },
            (error) =>{
              console.log(error);
            }
          )
        }
      }
    }

  }


  calcPercentageCount(){
    this.percentageCount = (this.fidelityUser.ordersCount / this.fidelityUser.fidelityCount) * 100;
  }

  calcPercentageValue(){
    this.percentageValue = (this.fidelityUser.ordersValue / this.fidelityUser.fidelityValue) * 100;
  }

  direct(){
    if(this.fidelityUser.fidelityApproved){
      if(this.cartService.cartItems.length > 0){
        this.router.navigate([this.nameCompany, 'carrinho']);
      }
      else{
        if(this.typeParam.type == 3){
          this.router.navigate([this.nameCompany, 'B']);
        }
        else{
          this.router.navigate([this.nameCompany]);
        }
  
      }
    }
    else{
      this.toastr.warning(`Complete os requisitos para utilizar seu beneficio`, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000});
    }

  }
  
}
