import { Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef, AfterViewInit, Inject} from '@angular/core';
import { ApiService } from './shared/services/API/api.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Route, Router, RouterStateSnapshot } from '@angular/router';
import { SplashComponent } from './shared/components/splash/splash.component';
import { DOCUMENT } from '@angular/common';
import { CacheService } from './shared/services/Cache/cache.service';
import { filter } from 'rxjs';
import { AuthLayoutComponent } from './shared/components/layout/auth-layout/auth-layout.component';
import { MenuComponent } from './views/menu/menu.component';
import { Inicialization } from './Interfaces/company';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit, AfterViewInit {

  //#region Variaveis de elemento HTML
  //#endregion

  //#region Variaveis Locais

    empresa!: Inicialization;

  //#endregion

  //#region Inicializadores
    constructor(private renderer: Renderer2,
      // private route: ActivatedRoute,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private authLayout: AuthLayoutComponent,
      private menuComponent: MenuComponent,
      private cacheService: CacheService,
      // private route: ActivatedRoute,
      // private state: RouterStateSnapshot,
      @Inject(DOCUMENT) private document: Document

      ){
    }
    ngOnInit(): void {
      this.verifiSize();

    }
    ngAfterViewInit(): void {


    }
  //#endregion

  //#region Funções de estilo (Style)
    @HostListener('window:resize', [])
    onWindowResize(){
      this.verifiSize();
    }

    //Faz uma verificação assim que a aplicação incia para preparar a responsividade
      verifiSize(){
        if(window.innerWidth <= 470)
        {
          this.document.body.classList.add('mobile')
          this.document.body.classList.remove('tablet')
          this.document.body.classList.remove('desktop')
        }
        else if(window.innerWidth > 470 && window.innerWidth < 847){
          this.document.body.classList.add('tablet')
          this.document.body.classList.remove('mobile')
          this.document.body.classList.remove('desktop')
        }
        else if(window.innerWidth >= 847){
          this.document.body.classList.add('desktop')
          this.document.body.classList.remove('mobile')
          this.document.body.classList.remove('tablet')
        }
      }

  //#endregion

  //#region Funções de lógica (Logic)



  //#endregion













}
