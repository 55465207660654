import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { ConnectionService } from '../../shared/services/Connection/connection.service';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { ApiService } from '../../shared/services/API/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IdentificationGuard } from '../identification/identification.guard';
import { CartService } from '../../shared/services/Cart/cart.service';
import { typeInterface } from 'src/app/Interfaces/company';

export interface Authentication{
 response: contentAuthentication;
}
export interface contentAuthentication{
  id_table:     string;
  tableNumber:  number;
  tableStatus:  number;
  cardNumber:   number;
  cardStatus:   number;
  id_company:   number;
}

@Injectable({
  providedIn: 'root',
})
export class verificationGuard implements CanActivate {

  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService, private cartService: CartService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const fullUrl = state.url;
    const identification = route.params['identification'];
    var partUrl = fullUrl.split('/')
    const nameCompany = partUrl[1];
    const typeParam = partUrl[2]
    let id_company!: number;

    //#region responsavel por resgatar o nome da empresa

      this.connection.nameCompanySubject.next(nameCompany)

    //#endregion


    //Identifica se existe mesa/comanda e se esta ativa
    if(typeParam == 'QrCode')
    {
      if (identification !== '') {
        return new Observable<boolean>((observer) => {
          //Valida se o id do Qrcode é valido
          this.apiService.getAuthentication(identification, nameCompany).subscribe(
            (data) => {
              if(data)
              {
                const authentication = data.response
                id_company = data.response.id_company

                //Se o qrcode for comanda entra aqui
                  if(authentication.cardNumber != 0)
                  {
                    //Verifica se existe pedido na Comanda
                    this.apiService.getOrderConsumption(authentication.cardNumber, 0, id_company).subscribe((response) =>{
                      if(!response.orderEmpty){
                        this.orderSucess(response);
                      }
                      else{
                        this.orderEmpty()
                      }
                    },
                    (error) =>{
                      this.router.navigate(['/Authentication']);
                    }
                    )

                    if(authentication.cardStatus == 20){
                      this.connection.permissionRequestOrderSubject.next(false);
                    }
                    else{
                      this.connection.permissionRequestOrderSubject.next(true);
                    }
                    
                    const type: typeInterface = {
                      type: 2,
                      qrCodeNumber: identification,
                      numberParam: authentication.cardNumber.toString()
                    }

                    this.connection.updateType(type)
                  }
                  else
                  {
                    //Verifica se existe pedido na Mesa
                    this.apiService.getOrderConsumption(0, authentication.tableNumber, id_company).subscribe((response) =>{
                      if(!response.orderEmpty)
                      {
                        this.orderSucess(response);
                      }
                      else{
                        this.orderEmpty();
                      }
                    },
                    (error) =>{
                      this.router.navigate(['/Authentication']);
                    }
                    )

                    if(authentication.cardStatus == 20){
                      this.connection.permissionRequestOrderSubject.next(false);
                    }
                    else{
                      this.connection.permissionRequestOrderSubject.next(true);
                    }

                    this.cacheService.removeItemLocal('number_local')

                    const type: typeInterface = {
                      type: 1,
                      qrCodeNumber: identification,
                      numberParam: authentication.tableNumber.toString()
                    }

                    this.connection.updateType(type)
                  }
              }
              this.cacheService.setItemLocalTimeout('qrCode_key', identification, 120)
              this.cacheService.clearCacheTimeout('counter_key')
              observer.next(true);
              observer.complete();
              if(nameCompany)
              {
                this.router.navigate([ nameCompany, 'P'])
              }
            },
            (error) => {
              console.error('Erro ao obter autenticação:', error);
              this.router.navigate(['/Authentication']);
              observer.next(false);
              observer.complete();
            }
          );
      });
      }
    }
    else if(typeParam == 'Counter'){
      if (identification !== '') {
        return new Observable<boolean>((observer) => {

          const type: typeInterface = {
            type: 3,
            qrCodeNumber: identification,
            numberParam: 0
          }
          this.connection.updateType(type)
          observer.next(true)
          observer.complete();
          if(nameCompany)
          {
            this.cacheService.setItemLocalTimeout('counter_key', identification, 120);
            this.cacheService.clearCacheTimeout('qrCode_key');
            this.router.navigate([ nameCompany, 'B']);
          }
        });
      }
    }


    return true

  }

  orderEmpty(){
    const session = this.cacheService.getItemLocal('session_id')
    if(session){
      this.cacheService.removeItemLocal('session_id')
    }
  }

  orderSucess(response: any){
    this.cartService.idTableCardSubject.next(response.response.orders[0].table.toString())
    this.cacheService.setItemLocal('number_local', response.response.orders[0].table)

    if(response.response.orders.length == 1)
    {
      this.cacheService.setItemLocal('session_id', response.response.orders[0].id_order)
    }
  }
}

