import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-change',
  templateUrl: './form-change.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './form-change.component.css']
})
export class FormChangeComponent implements OnInit{
  selected: boolean = false;
  typeSelected: number = 0;
  @Input() orderPrice = 0;
  @Output() change = new EventEmitter<number>();
  changeForValue: string = 'R$ 0,00';
  minPriceNot: boolean = false;


  ngOnInit(): void {
  }

  changeFor(type: number){
    const value = this.changeForValue.replace(/[^\d.,]/g, '').replace(',', '.');
    const valueNumber = Number(value.replace(',', '.'))


    if(valueNumber > this.orderPrice)
    {
      this.typeSelected = type
      this.selected = true
      this.minPriceNot = false;
      if(type == 2){
        this.change.emit(0)
      }
      else{
        this.change.emit(valueNumber)
      }
    }
    else{
      this.minPriceNot = true
    }


  }

  changeInput(event: any){
    const value = event.target.value.replace(/[^\d.,]/g, '').replace(',', '.');
    const valueNumber = Number(value.replace(',', '.'))

    if(valueNumber > this.orderPrice)
    {
      this.minPriceNot = false;
    }
    else{
      this.minPriceNot = true
    }
  }

  changeButton(){
    this.selected = false;
    this.typeSelected = 0;
    this.change.emit(0)
  }
    //#endregion
}
