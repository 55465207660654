<div class="side-bar" id="nav-side" *ngIf="this.company">
  <div class="info-company" [ngStyle]="{'background-image': 'url('+ imgBg +')'}">
      <div class="img-company" (click)="sharedEvent()">
        <img class="img-company-image" [src]="imgLogo" data-bs-toggle="modal" data-bs-target="#modalInfoEmpresa">
      </div>
      <!-- <div style="padding: 5px; width: 100%;">
        <h5 style="color: white;font-size: 20px;">{{ this.company.company.companyName }}</h5>
      </div> -->
  </div>
  <div class="menu-side-bar">
      <ul>
        <ng-container *ngFor="let category of tabela; let i = index">
          <ng-container *ngIf=" this.filter == '' ">
            <li>
              <a class="links-navegation" (click)="clicked = true; colorBarClick($event, i);" #meulink>{{ category.categoryName }} <i class="fa-solid fa-chevron-right chevron-side active"></i></a>
            </li>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let category of this.tabelaFiltrada; let i = index">
          <ng-container *ngIf=" this.filter != '' ">
            <li>
              <a class="links-navegation" (click)="clicked = true; colorBarClick($event, i);" #meulink>{{ category.categoryName }} <i class="fa-solid fa-chevron-right chevron-side active"></i></a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
  </div>
  <div class="div-pedeaqui">
    <img src="assets/logo/logo-pedeaqui.png" class="img-pedeaqui">
  </div>
</div>
<!-- <div class="modal fade" id="modalInfoEmpresa" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Sobre o estabelecimento</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="description-company">
          <div class="description-first">
            <div class="description-title">
              <div>
                <span>Empresa</span>
              </div>
          </div>
            <div class="description-body">
              <div>
                <span>Rua Coronel Amazonas Marcondes, 1482 - Cabral, Curitiba - PR, 80.035-230, Brasil</span>
                <div class="icon-container">
                  <a href="https://www.google.com.br/maps/place/R.+Cel.+Amazonas+Marcondes,+1482+-+Ah%C3%BA,+Curitiba+-+PR,+80035-230/@-25.3997381,-49.2592566,17z/data=!3m1!4b1!4m6!3m5!1s0x94dce69ba473c6ed:0x2b9b9b5ea2cfb6e1!8m2!3d-25.399743!4d-49.2566817!16s%2Fg%2F11cr_gkkzl?entry=ttu"><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div> -->
