import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { ApiService } from 'src/app/shared/services/API/api.service';

@Injectable({
  providedIn: 'root',
})
export class redirectGuard implements CanActivate {
  private pageReloaded!: boolean;

  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService) {

    this.connection.redirect$.subscribe((data) =>{
      this.pageReloaded = data;

    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let returnRedirect!: boolean

    if(this.pageReloaded)
    {
      this.connection.redirectSubject.next(false)
      let nameCompany = this.cacheService.getItemLocal('nameCompany')
      let qrCode = this.cacheService.getItemLocalTimeout('qrCode_key')
      let counter = this.cacheService.getItemLocalTimeout('counter_key')

      if(qrCode != null)
      {
        if(nameCompany != '')
        {
          this.router.navigate([nameCompany, 'P']);
        }
        else{
          this.router.navigate(['']);
        }

      }
      else if(counter != null)
      {
        if(nameCompany != '')
        {
          this.router.navigate([nameCompany, 'B']);
        }
        else{
          this.router.navigate(['']);
        }
      }
      else{

        if(nameCompany != '')
        {
          this.router.navigate([nameCompany]);
        }
        else{
          this.router.navigate(['']);
        }

      }

    }
    else if(this.pageReloaded)
    {
      returnRedirect = true
    }

    return returnRedirect

  }
}

