import { Component, Input, OnInit } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';

@Component({
  selector: 'app-time-await',
  templateUrl: './time-await.component.html',
  styleUrl: './time-await.component.css'
})
export class TimeAwaitComponent implements OnInit{

  company!: Company;
  @Input() typeOrder: number = 0;
  @Input() visualization: number = 0;
  @Input() createdAtOrder!: string;
  timeTo!: string;
  timeFrom!: string;
  disable: boolean = false;

  constructor(private connection: ConnectionService){}

  ngOnInit(): void {
      this.connection.company$.subscribe((data) =>{
        this.company = data
      })

      try {
        if(this.visualization == 0){
          if(this.typeOrder == 5){
            this.timeFrom = this.company.company.times.deliveryFrom.toString()
            this.timeTo = this.company.company.times.deliveryTo.toString()
          }
          else{
            this.timeFrom = this.company.company.times.counterFrom.toString()
            this.timeTo = this.company.company.times.counterTo.toString()
          }
          
          if(this.timeFrom == '0' || this.timeTo == '0')
          {
            this.disable = true;
          }
  
        }
        else if(this.visualization == 1){
          if(this.typeOrder == 5){
            this.timeFrom = this.timeFromFormatted()
            this.timeTo =  this.timeToFormatted()
          }
          else{
            this.timeFrom = this.timeFromFormatted()
            this.timeTo =  this.timeToFormatted()
          }
        }
      } catch (error) {
        this.disable = true;
        console.log(error)
      }
  }

  timeFromFormatted(){
    let dateTime = new Date(this.createdAtOrder);

    let temporaryFrom

    if(this.typeOrder == 5){
      temporaryFrom = this.company.company.times.deliveryFrom;
    }
    else{
      temporaryFrom = this.company.company.times.counterFrom;
    }

    if(temporaryFrom == 0){
      this.disable = true;
      return '0'
    }

    dateTime.setMinutes(dateTime.getMinutes() + temporaryFrom);

    let newHour = dateTime.getHours();
    let newMinutes = dateTime.getMinutes();
    let newMinutesFormatted = '';

    if (newMinutes < 10) {
      newMinutesFormatted = '0' + newMinutes;
    }
    else{
      newMinutesFormatted = newMinutes.toString();
    }

    return newHour + ":" + newMinutesFormatted
  }

  timeToFormatted(){
    let dateTime = new Date(this.createdAtOrder);
    let temporaryTo

    if(this.typeOrder == 5){
      temporaryTo = this.company.company.times.deliveryTo;
    }
    else{
      temporaryTo = this.company.company.times.counterTo;
    }

    if(temporaryTo == 0){
      this.disable = true;
      return '0'
    }

    dateTime.setMinutes(dateTime.getMinutes() + temporaryTo);

    let newHour = dateTime.getHours();
    let newMinutes = dateTime.getMinutes();
    let newMinutesFormatted = '';

    if (newMinutes < 10) {
      newMinutesFormatted = '0' + newMinutes;
    }
    else{
      newMinutesFormatted = newMinutes.toString();
    }

    return newHour + ":" + newMinutesFormatted
  }

}
