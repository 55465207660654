<div class="box-loading">
  <div class="box-loading__content">
    <div class="box-loading__content__caurosel">
      <div>
        <img [src]="currentImage" style="width: 100%;" alt="Imagem">
      </div>

      <span class="text">{{ this.textExibing }}</span>
    </div>
  </div>
</div>
