<div class="main" *ngIf="this.userConfirmation">
    <div class="header" *ngIf="this.user">
        <span style="font-size: 17px; color: #5d5d5d;">Bem vindo</span>
        <span>{{ this.user.name }}</span>
    </div>
    <div class="box-option">
      <div class="option" [routerLink]="['/', this.nameCompany, 'pedidos']">
        <i class="fa-solid fa-book"></i>
        <div class="content-option">
          <span class="title-option">Pedidos</span> 
          <span class="description-option">Meus pedidos</span>
        </div>
      </div>
      <ng-container *ngIf="this.fidelityGet">
        <div class="option" (click)="navigateFidelity()" *ngIf="this.fidelityUser.fidelity == true">
          <i class="fa-solid fa-handshake"></i>
          <div class="content-option">
            <span class="title-option">Fidelidade</span> 
            <span class="description-option">Minhas fidelidades</span>
          </div>
        </div>
      </ng-container>

      <div class="option disabled-option">
        <i class="fa-solid fa-ticket"></i>
        <div class="content-option">
          <span class="title-option">Cupons</span>
          <span class="description-option">Meus cupons de desconto</span> 
        </div>
        <div class="button-short">
          <button>Em breve</button>
        </div>
      </div>

      <div class="option disabled-option">
        <i class="fa-solid fa-location-dot"></i>
        <div class="content-option">
          <span class="title-option">Endereços</span> 
          <span class="description-option">Meus endereços de entrega</span>
        </div>
        <div class="button-short">
          <button>Em breve</button>
        </div>
      </div>

      <div class="option" (click)="this.openLogOut = true">
          <i class="fa-solid fa-right-to-bracket"></i>
          <div class="content-option">
            <span class="title-option">Sair</span>
            <span class="description-option">Sair do seu perfil</span>  
          </div>
      </div>
        
    </div>
</div>



<div *ngIf="!this.userConfirmation">
    <app-top-menu-two></app-top-menu-two>
    <div class="container" style="overflow: auto;">
      <div class="navegation-header d-flex align-items-center">
        <div class="box-title">
          <h3 style="margin-bottom: 0px;">Entre ou Cadastre-se</h3>
        </div>
      </div>
      <form (ngSubmit)="submitForm()">
        <div style="padding-bottom: 90px;" class="fist-box">
          <div class="user-data">
            <div class="d-flex px-2 flex-column box-data">
              <span>Número de celular:</span>
              <input class="form-control" type="text" placeholder="(_ _) _ _ _ _ _ - _ _ _ _" [(ngModel)]="this.phoneNumber" name="phoneNumber" [ngClass]="{'is-valid': this.corretFormatPhoneNumber == true, 'is-invalid': this.corretFormatPhoneNumber == false}" (input)="onInputChange($event)" maxlength="14" #inputPhone required>
            </div>
            <div class="d-flex px-2 flex-column box-data">
              <span>Nome e sobrenome:</span>
              <input class="form-control" type="text" [(ngModel)]="this.name" name="name" placeholder="Nome e sobrenome" [ngClass]="{'is-valid': this.lengthInputName == true, 'is-invalid': this.lengthInputName == false}" (input)="onInputChangeName($event)" required="true" #inputName>
            </div>
            <!-- <p class="warning">Para realizar seu pedido vamos precisar de suas informações</p> -->
          </div>
        </div>
        <div class="bottom-navegation">
          <div class="bottom-navegation__box">
            <button type="submit" class="buttonConfirmation not-disabled" *ngIf="this.alterationButton" [ngClass]="{'not-disabled': lengthInputName && lengthInputPhone}">
                Entrar
            </button>
            <button type="submit" class="buttonConfirmation not-disabled" *ngIf="!this.alterationButton" [ngClass]="{'not-disabled': lengthInputName && lengthInputPhone}">
                Cadastrar
            </button>
          </div>
        </div>
    </form>
    </div>
    
</div>

<div class="modal" [ngClass]="{'show': openLogOut}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Sair</h1>
      </div>
      <div class="modal-body">
        <span>Deseja sair da sua conta?</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-edit" (click)="this.openLogOut = false">Fechar</button>
        <button type="button" class="btn btn-confirmation" (click)="this.disconnectUser()">Sair</button>
      </div>
    </div>
  </div>
</div>


<app-bottom-menu></app-bottom-menu>
