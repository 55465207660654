import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  sections!: ElementRef[]
  indexPosition = 0
  scrollPosition = 0

  private scrollPositionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private indexPositionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  sectionIndex: number = 0;

  scrollingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  scrolling$: Observable<boolean> = this.scrollingSubject.asObservable();

  constructor() {}

  loadSections(objects: ElementRef[]){
    this.sections = objects;
  }


  setScrollIndex(index: number): void {
    // this.indexPositionSubject.next(index)
    this.indexPosition = index;
  }
  setScrollPosition(position: number): void {
    this.scrollPosition = position;
    // this.scrollPositionSubject.next(position)
  }

  getScrollPosition(): number {
    return this.scrollPosition;
  }
  getScrollIndex(): number {
    return this.indexPosition;
  }

  // getScrollPosition(): Observable<number> {
  //   return this.scrollPositionSubject.asObservable();
  // }

  // getScrollIndex(): Observable<number> {
  //   return this.indexPositionSubject.asObservable();
  // }


}
