<!-- <app-slide [images]="images" style="position: fixed;">
</app-slide> -->
<div class="body-authentication" style="z-index: 1000;">


  <div class="img-scan">
    <img src="../../../../assets/icons/scanQr-2.png" />
    <img class="img-gif" style="width: 45px;" src="../../../../assets/icons/scanQr-4.gif">
  </div>
  <div class="bottom">
    <div class="text-warning">
      <h5>Sessão expirada!</h5>
      <h5>Escaneie o QrCode novamente.</h5>
    </div>
    
    <div>
      <img style="width: 200px; height: 120px;" src="assets/logo/logo-pedeaqui.png" />
    </div>

    <div>
      <span style="font-weight: 600; font-size: 18px;">&copy; Powered by Pedeaqu!</span>
    </div>
  </div>



</div>



