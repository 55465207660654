<!-- <div class="box-add-cupom">
    <i class="fa-solid fa-ticket"></i>
    <span>Insira um cupom</span>
</div> -->
<div *ngIf="!this.cupomselecionado" class="cupom-not-selected">

    <div class="box-input" [ngClass]="{'info': this.coupomNotValid || this.stringCoupomEmpty}">
        <div class="input-infos">
            <span style="color: var(--primary-color);">Cupom</span>
            <input type="text" class="form-control" id="cupom" placeholder="Digite seu cupom" [ngModel]="this.cupomDigit" (ngModelChange)="this.cupomDigit= $event.toUpperCase(); this.checkChange($event)">
        </div>

        <button class="button-add" type="button" id="button-addon2" (click)="this.verifiCoupom(this.cupomDigit)">Adicionar</button>
    </div>
    <ng-container *ngIf="this.coupomNotValid">
        <span *ngIf="this.coupomNotValid.empty">Cupom inválido.</span>
        <span *ngIf="this.coupomNotFreeFee">Cupom válido apenas para entrega.</span>
        <span *ngIf="this.minValue">Pedido minimo de {{ this.coupomNotValid.minValue | currency:'BRL' }}.</span>
    </ng-container>
    <span *ngIf="this.stringCoupomEmpty" style="font-size: 13px; color: rgb(177, 177, 177);">Por favor insira um cupom.</span>
</div>

<div class="cupom-selected" *ngIf="this.couponSelected && this.cupomselecionado">
    <div class="cupom-selected-content">
        <div class="group-img">
            <img src="./../../../../assets/icons/cupom.png" />
            <div class="span">
                <span class="span-coupon">{{ this.couponSelected.coupon }}</span>
                <span>Cupom aplicado</span>
            </div>
        </div>

        <!-- <input style="color: green;" type="text" class="form-control" id="cupom" [value]="this.couponSelected.coupon" disabled> -->
        <button class="button-add" type="button" id="button-addon2" (click)="this.changeButton()">Alterar</button>
    </div>

</div>